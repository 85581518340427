import React, { useCallback, useEffect, useRef, useState } from "react";
import Layout from "../../layout";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import { useLocation, useNavigate } from "react-router-dom";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { Row, Col, Button, Nav, Tab } from "react-bootstrap";
import ReactPlayer from "react-player";
import SearchIcon from "../../assets/images/search.svg";
import Ex1 from "../../assets/images/Surgery.svg";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import TopBar from "../../components/TopBarNavigation";
import FilterIcon from "../../assets/images/filter.svg";
import checkIcon from "../../assets/images/check (1).png";
import Auth from "../../libs/auth";
import ProfileCompletionModal from "../AddProfileData/ProfileCompletionModal";

function SurgeryTemplateList() {
  const user = Auth.getCurrentUser();
  const [showProfileCompletionModal, setShowProfileCompletionModal] =
    useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);
  const [selectedBodyPart, setSelectedBodyPart] = useState([]); // Maintain selected movement types for each body part
  const [bodyPartData, setBodyPart] = useState({
    has_more: false,
    items: [],
  });
  const [templateList, setTemplateList] = useState({
    has_more: false,
    items: [],
  });
  const [filter, setFilter] = useState({
    body_part_id: "",
    title: "",
    searchTerm: "",
    patient_id: "",
  });
  const [page, setPage] = useState(1);

  const [isBodyPartUpdated, setIsBodyPartUpdated] = useState(false); // New state to track body part update

  useEffect(() => {
    if (location?.state?.bodypartId) {
      handleCheckboxChange(location?.state?.bodypartId);
    }
  }, [location?.state?.bodypartId]);

  useEffect(() => {
    if (isBodyPartUpdated || filter?.searchTerm) {
      // Trigger the API call only when the body part is updated
      getTemplateList();
      setIsBodyPartUpdated(false); // Reset the update state
    }
  }, [isBodyPartUpdated, filter, selectedBodyPart, page]);

  UseEffectOnce(() => {
    getBodyPartData();
  }, []);

  async function getBodyPartData() {
    try {
      const data = await get(`/bodyPartList?isTemplate=true`, getAuthConfig());
      if (data?.status === 200) {
        setBodyPart((p) => ({ ...p, items: data?.data?.data }));
        setBodyPart((p) => ({ ...p, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }

  async function getTemplateList() {
    try {
      setLoading(true); // Set loading to true before the API call
      const searchTerm = filter?.searchTerm || "";
      let queryParams = `pageNumber=${page}`;

      if (searchTerm) {
        queryParams += `&searchTerm=${searchTerm}`;
      }

      selectedBodyPart.forEach((id, index) => {
        queryParams += `&body_part_id[${index}]=${id}`;
      });

      const data = await get(`/templateList?${queryParams}`, getAuthConfig());
      if (data?.status === 200) {
        if (page === 1) {
          setTemplateList((p) => ({ ...p, items: data?.data?.data }));
        } else {
          setTemplateList((p) => ({
            ...p,
            items: templateList?.items?.concat(data?.data?.data),
          }));
        }
        setTemplateList((p) => ({ ...p, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    } finally {
      setLoading(false); // Set loading to false after the API call
    }
  }

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return; // Early return if loading is true
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && templateList?.has_more) {
          if (page === 3) {
            if (!user?.profileCompleted) {
              setShowProfileCompletionModal(true);
            } else {
              setPage((prevPageNumber) => prevPageNumber + 1);
            }
          } else {
            setPage((prevPageNumber) => prevPageNumber + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [templateList?.has_more, page, loading]
  );

  const handleCheckboxChange = (bodyPartId) => {
    setPage(1);
    const updatedSelectedMovementTypes = [...selectedBodyPart];

    if (!updatedSelectedMovementTypes.includes(bodyPartId)) {
      updatedSelectedMovementTypes.push(bodyPartId);
    } else {
      const indexToRemove = updatedSelectedMovementTypes.indexOf(bodyPartId);
      if (indexToRemove !== -1) {
        updatedSelectedMovementTypes.splice(indexToRemove, 1);
      }
    }

    setSelectedBodyPart(updatedSelectedMovementTypes);
    setIsBodyPartUpdated(true); // Signal that body part has been updated
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    const searchTerm = e.target.value;
    setFilter((p) => ({ ...p, searchTerm }));
    setPage(1);
  };


  return (
    <>
      <Layout>
        <TopBar
          backlink={"/true"}
          title={location?.state?.bodyPart}
          tab={"Physiotherapist"}
          tabChild={"BodyPart"}
          tabSmallChild={"Protocol"}
        />

        <div
          className="main-content-part foot-page p-0"
          defaultActiveKey="first"
        >
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col xl={3} lg={4} className="d-none d-lg-block">
                <div className="foot-sidebar p-0 position-sticky scrollable-column template">
                  <div className="accordion w-100 " id="regularAccordionRobots">
                    {bodyPartData?.items.map((item, index) => (
                      <div
                        key={index}
                        className={`accordion-item border-0 mx-2 ${
                          location?.state?.bodypartId === item.id
                            ? "active"
                            : ""
                        }`}
                      >
                        <Nav variant="pills" className="flex-column m-0">
                          <Nav.Item
                            key={index}
                            className="d-flex justify-content-start align-items-center mb-0"
                          >
                            <div className="checkbox me-2">
                              <input
                                type="checkbox"
                                id={`checkbox_${item?._id + index}`}
                                checked={selectedBodyPart.includes(item?._id)}
                                onChange={() => {
                                  setPage(1);
                                  handleCheckboxChange(item?._id);
                                }}
                              />
                              <label
                                htmlFor={`checkbox_${item?._id + index}`}
                              ></label>
                            </div>
                            <Nav.Link eventKey={`sub-${index}`}>
                              {item?.title}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
              <Col xl={9} lg={8}>
                <div className="content-data  pt-5 pl-4 pr-4 scrollable-column">
                  <div className="serchPart">
                    <div className="searchInput">
                      <div className="searchIcon">
                        <img src={SearchIcon} alt="searchIcon" />
                      </div>
                      <input
                        type="text"
                        placeholder="Search Protocol"
                        onChange={handleSearchChange}
                      />
                      <div
                        className="filterIcon"
                        onClick={(e) => {
                          handleShowFilter();
                        }}
                      >
                        <img src={FilterIcon} alt="filterIcon" />
                      </div>
                    </div>
                  </div>

                  <Row>
                    {loading && page === 1 ? (
                      <div className="preloader-whirlpool text-center align-items-center">
                        <div className="whirlpool"></div>
                      </div>
                    ) : (
                      <>
                        {templateList?.items &&
                          templateList?.items?.length <= 0 && (
                            <div className="text-center">No Protocol found</div>
                          )}
                        {templateList?.items &&
                          templateList?.items?.length > 0 &&
                          templateList?.items?.map((item, index) => {
                            return (
                              <Col
                                xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                ref={lastBookElementRef}
                                key={index}
                              >
                                <div className="assign-doctor-card p-0 px-3 pb-3">
                                  <div className="doctor-card-content">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();

                                        navigate("/createExercise", {
                                          state: {
                                            cart: item?.exerciseId,
                                            bodyPart:
                                              location?.state?.bodyPart,
                                            routine: { name: item?.name },
                                            patient_id:
                                              location?.state?.patient_id,
                                            patientDetail:
                                              location?.state?.patientDetail,
                                            bodyPartImage:
                                              location?.state?.bodyPartImage,
                                            isRenew: location?.state?.isRenew,
                                          },
                                        });
                                      }}
                                    >
                                      <div className="image-part py-3">
                                        <img
                                          src={Ex1}
                                          height="200px"
                                          width={"100%"}
                                          style={{ borderRadius: "14px" }}
                                          alt="Patient"
                                        />
                                      </div>
                                      <div className="text-part text-start ">
                                        <span
                                          className="exerciseLabfont name"
                                          style={{ fontWeight: "500" }}
                                        >
                                          {item?.name}
                                        </span>
                                        <br />
                                        <span
                                          className="exerciseLabfontSUB name p-1"
                                          style={{
                                            color: "#ADADAD",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {moment(item?.created_on).format(
                                            "DD MMM YYYY"
                                          )}
                                        </span>
                                      </div>

                                      <div className="divi p-2">
                                        <span className="divider"></span>
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex justify-content-center "
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setCart(item?.exerciseId);
                                        handleShow();
                                      }}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          color: "#FF6036",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        +{item?.exerciseId?.length} Exercises
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                      </>
                    )}
                    {loading && page > 1 && (
                      <div className="preloader-whirlpool text-center align-items-center">
                        <div className="whirlpool"></div>
                      </div>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <ProfileCompletionModal
          show={showProfileCompletionModal}
          onHide={() => setShowProfileCompletionModal(false)}
          From={"Template"}
        />

        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton className="justify-content-start">
            <Modal.Title className="text-center">Exercise List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body-content">
              {cart &&
                cart.length > 0 &&
                cart.map((item, index) => {
                  return (
                    <div
                      className="exercise-card mb-3 w-100"
                      style={{ boxShadow: "none" }}
                      key={index}
                    >
                      <div className="img-text-part w-75">
                        <div className="img-block">
                          <img
                            src={item?.exerciseImage}
                            height={130}
                            width={200}
                            style={{ borderRadius: "10%" }}
                            alt="ex-1"
                            className="img-responsive"
                          />
                        </div>
                        <div className="text-part ps-3">
                          <div className="info-section">
                            <h6 className="data m-2">{item?.title} </h6>
                          </div>

                          <div className="info-section">
                            <span className="data m-2">
                              {item?.equipment && item.equipment.length > 0
                                ? item.equipment.map((equip, index) => (
                                    <React.Fragment key={index}>
                                      {index > 0 && " , "}
                                      {equip}
                                    </React.Fragment>
                                  ))
                                : "No Equipment"}
                            </span>
                          </div>
                          <div className="info-section">
                            <span className="data m-2">
                              {item?.body_part_id &&
                                item?.body_part_id.length > 0 &&
                                item?.body_part_id.map((itemEq, ind) => (
                                  <React.Fragment key={ind}>
                                    {ind > 0 && " , "}
                                    {itemEq?.title}
                                  </React.Fragment>
                                ))}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="checkbox me-2 w-25 d-flex justify-content-end"
                        style={{ cursor: "pointer" }}
                      >
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item className="d-flex justify-content-start align-items-center">
                            <div className="checkbox me-2 sidebarCheckbox">
                              <input
                                type="checkbox"
                                id={`checkbox_${"morning"}`}
                                checked={true}
                              />
                              <label htmlFor={`checkbox_${"morning"}`}></label>
                            </div>
                            <Nav.Link eventKey="first"></Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <hr />
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showFilter} onHide={handleCloseFilter} centered size="md">
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="body-content">
              <ul className="filterList">
                {bodyPartData?.items &&
                  bodyPartData?.items.length > 0 &&
                  bodyPartData.items.map((item, index) => (
                    <li
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        handleCheckboxChange(item?._id);
                      }}
                    >
                      <a>
                        {item?.title}
                        {selectedBodyPart.includes(item?._id) && (
                          <div className="select-img">
                            <img
                              src={checkIcon}
                              alt="checkIcon"
                              style={{ display: "block" }}
                            />
                          </div>
                        )}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="theme-button"
              onClick={(e) => {
                e.preventDefault();
                handleCloseFilter();
              }}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
}
export default SurgeryTemplateList;
