import React, { useEffect, useState } from "react";
import logo from "../assets/images/Virtue Life logo1.svg";
// import logo from "../assets/images/RecureMe.svg";
import "../assets/css/sidebar.css";
import logout from "../assets/images/signOut.svg";
import home from "../assets/images/home.svg";
import patient from "../assets/images/patients.svg";
import point from "../assets/images/point.svg";
import homeActive from "../assets/images/homeActive.svg";
import pointActive from "../assets/images/pointActive.svg";
import heartSvg from "../assets/images/heartBlack.svg";
import heartFilled from "../assets/images/heartFilled.svg";
import patientActive from "../assets/images/patientActive.svg";
import { useLocation, Link, useNavigate } from "react-router-dom";
import folderSvg from "../assets/images/folder-download.svg";
import folderSvgBlack from "../assets/images/folderBlack.svg";
import MyExercise from "../assets/images/myExercise.svg";
import MyExerciseFill from "../assets/images/myExerciseFill.svg";
import CommunitySidebar from "../assets/images/communitySidebad.svg";
import CommunityFillSidebar from "../assets/images/communityFillSidebar.svg";
import payment from "../assets/images/payment.svg";
import paymentActive from "../assets/images/paymentActive.svg";
import Auth from "../libs/auth";
import ProfileCompletionModal from "../pages/AddProfileData/ProfileCompletionModal";
import CalendarActive from "../assets/images/calendar.svg";
import Calendar from "../assets/images/calendarbefore.svg";
import ClinicVerficationModal from "./ClinicVerificationModal";
import LimitedAccessModal from "./LimitedAccessModal";
const PublicSidebar = () => {
  const location = useLocation();
  const activePage = location.pathname.replace("/", "");
  const navigate = useNavigate();
  const [LimitedAccessModalstate, setLimitedAccessModalstate] = useState(false);
  const pointsActivePaths = [
    "exercise-library",
    "exercise-list",
    "template-list",
    "createExercise",
  ];
  const user = Auth.getCurrentUser();
  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const handleLimitedAccess = () => {
    setLimitedAccessModalstate(true);
  };
  const [showProfileCompletionModal, setShowProfileCompletionModal] =
    useState(false);
  const [showClinicModal, setShowClinicModal] = useState(false);

  const handleAddPatientClick = () => {
    // if (user?.profileCompleted === false) {
    //   setShowProfileCompletionModal(true);
    // }
    // else

    if (
      data?.associatedEntity != "Individual" &&
      data?.clinicVerified === false
    ) {
      setShowClinicModal(true);
    } else {
      navigate("/personalInformation");
    }
  };

  useEffect(() => {
    if (
      data?.associatedEntity != "Individual" &&
      data?.clinicVerified === false
    ) {
      setShowClinicModal(true);
    } else {
      setShowClinicModal(false);
    }
  }, [data]);

  const handleConditionalNavigation = (event, path) => {
    // Prevent default link action
    event.preventDefault();

    // Check conditions before navigating
    if (
      data?.associatedEntity !== "Individual" &&
      data?.clinicVerified === false
    ) {
      // Optionally, show a modal or an alert if needed
      // e.g., setShowClinicModal(true);
      console.log("Navigation prevented due to clinic verification.");
    } else {
      // If conditions are met, navigate to the path
      navigate(path);
    }
  };

  return (
    <>
      <div className="sidebar-main">
        <div className="logo-part">
          <Link to={"/dashboard"}>
            <img src={logo} alt="docpy-logo" width={180} height={70} />
          </Link>
        </div>
        {/* <Link to={"/personalInformation"}>   */}
        <button className="btn btn-add" onClick={handleLimitedAccess}>
          + Add Patient
        </button>

        {/* </Link> */}
        <div className="nav-part publicpART">
          <div className="top-navpart">
            <a
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "not-allowed",
              }}
              onClick={handleLimitedAccess}
            >
              <div
                className={` ${
                  activePage === "dashboard" || activePage === "doctorList"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path
                    d="m19.267 7.544-6.32-6.325a4.172 4.172 0 0 0-5.894 0L.733 7.544A2.484 2.484 0 0 0 0 9.313v8.185A2.5 2.5 0 0 0 2.5 20h15a2.5 2.5 0 0 0 2.5-2.502V9.313a2.484 2.484 0 0 0-.733-1.769ZM12.5 18.332h-5v-3.28a2.5 2.5 0 1 1 5 0v3.28Zm5.833-.834c0 .46-.373.834-.833.834h-3.333v-3.28A4.168 4.168 0 0 0 10 10.882a4.168 4.168 0 0 0-4.167 4.17v3.28H2.5a.834.834 0 0 1-.833-.834V9.313a.84.84 0 0 1 .244-.59L8.23 2.4a2.506 2.506 0 0 1 3.537 0l6.321 6.326a.84.84 0 0 1 .244.587v8.185Z"
                    fill-rule="evenodd"
                    fill="#808080"
                    data-name="home"
                  />
                </svg>
                <p className="text">Home</p>
              </div>
            </a>
            <a
              to="/allPatient"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "not-allowed",
              }}
              onClick={handleLimitedAccess}
            >
              <div
                className={`${
                  activePage === "allPatient" ||
                  activePage === "patientDetails" ||
                  activePage === "history"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.5"
                  height="20.5"
                  viewBox="0 0 15.5 20.5"
                >
                  <path
                    id="clipboard-user_1_"
                    data-name="clipboard-user (1)"
                    d="M14.25,1.667h-.458A2.086,2.086,0,0,0,11.75,0H9.25A2.087,2.087,0,0,0,7.208,1.667H6.75A3.755,3.755,0,0,0,3,5.417V16.25A3.755,3.755,0,0,0,6.75,20h7.5A3.755,3.755,0,0,0,18,16.25V5.417A3.755,3.755,0,0,0,14.25,1.667ZM17.167,16.25a2.92,2.92,0,0,1-2.917,2.917H6.75A2.92,2.92,0,0,1,3.833,16.25V5.417A2.92,2.92,0,0,1,6.75,2.5h.833A.417.417,0,0,0,8,2.083,1.252,1.252,0,0,1,9.25.833h2.5A1.252,1.252,0,0,1,13,2.083a.417.417,0,0,0,.417.417h.833a2.92,2.92,0,0,1,2.917,2.917ZM10.5,10.833A2.5,2.5,0,1,0,8,8.333,2.5,2.5,0,0,0,10.5,10.833Zm0-4.167A1.667,1.667,0,1,1,8.833,8.333,1.668,1.668,0,0,1,10.5,6.667ZM14.654,14.9a.417.417,0,0,1-.3.5.423.423,0,0,1-.1.013.418.418,0,0,1-.4-.316,3.453,3.453,0,0,0-6.692,0,.417.417,0,1,1-.809-.2,4.286,4.286,0,0,1,8.308,0Z"
                    transform="translate(-2.75 0.25)"
                    fill="#808080"
                    stroke="#808080"
                    stroke-width="0.5"
                  />
                </svg>

                <p className="text">Patient</p>
              </div>
            </a>
            <Link
              to="/exercise-library"
              style={{ textDecoration: "none", color: "inherit" }}
              aria-disabled
            >
              <div
                className={`${
                  pointsActivePaths.includes(activePage)
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <img
                  src={
                    pointsActivePaths.includes(activePage) ? pointActive : point
                  }
                  alt=""
                  className="icon"
                />
                <p className="text">Library</p>
              </div>
            </Link>
            <a
              to="/selectedExercise"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "not-allowed",
              }}
              onClick={handleLimitedAccess}
            >
              <div
                className={`${
                  activePage === "selectedExercise"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16.912"
                  viewBox="6324 17379 20 16.912"
                >
                  <g data-name="Favorites – 2">
                    <g data-name="Group 3190">
                      <g data-name="Group 3170">
                        <g data-name="Path 15629">
                          <path
                            d="M6334 17395.911c-.1 0-.2-.02-.295-.061-.098-.043-2.429-1.073-4.791-2.927-1.405-1.103-2.53-2.27-3.34-3.471-1.044-1.545-1.574-3.148-1.574-4.765 0-1.613.487-3.007 1.408-4.033.972-1.082 2.34-1.654 3.96-1.654 1.153 0 2.328.52 3.492 1.546.46.406.847.83 1.14 1.183.293-.354.68-.777 1.14-1.183 1.164-1.026 2.339-1.546 3.492-1.546 1.62 0 2.988.572 3.96 1.654.921 1.026 1.408 2.42 1.408 4.033 0 1.617-.53 3.22-1.574 4.765-.81 1.2-1.935 2.368-3.34 3.47-2.362 1.855-4.693 2.885-4.791 2.928a.737.737 0 0 1-.295.061Zm-4.632-15.44c-1.19 0-2.18.402-2.866 1.165-.674.752-1.031 1.807-1.031 3.05 0 5.266 6.993 8.936 8.529 9.678 1.534-.743 8.53-4.42 8.53-9.677 0-1.244-.357-2.3-1.032-3.05-.685-.764-1.676-1.167-2.866-1.167-1.967 0-3.765 2.52-3.964 2.806a.812.812 0 0 1-1.336 0c-.199-.287-1.998-2.806-3.964-2.806Z"
                            fill="#808080"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M6334 17395.911c-.1 0-.2-.02-.295-.061-.098-.043-2.429-1.073-4.791-2.927-1.405-1.103-2.53-2.27-3.34-3.471-1.044-1.545-1.574-3.148-1.574-4.765 0-1.613.487-3.007 1.408-4.033.972-1.082 2.34-1.654 3.96-1.654 1.153 0 2.328.52 3.492 1.546.46.406.847.83 1.14 1.183.293-.354.68-.777 1.14-1.183 1.164-1.026 2.339-1.546 3.492-1.546 1.62 0 2.988.572 3.96 1.654.921 1.026 1.408 2.42 1.408 4.033 0 1.617-.53 3.22-1.574 4.765-.81 1.2-1.935 2.368-3.34 3.47-2.362 1.855-4.693 2.885-4.791 2.928a.737.737 0 0 1-.295.061Zm-4.632-15.44c-1.19 0-2.18.402-2.866 1.165-.674.752-1.031 1.807-1.031 3.05 0 5.266 6.993 8.936 8.529 9.678 1.534-.743 8.53-4.42 8.53-9.677 0-1.244-.357-2.3-1.032-3.05-.685-.764-1.676-1.167-2.866-1.167-1.967 0-3.765 2.52-3.964 2.806a.812.812 0 0 1-1.336 0c-.199-.287-1.998-2.806-3.964-2.806Z"
                            fill="transparent"
                            stroke="#808080"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            stroke-width=".2"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>

                <p className="text">Saved Exercise</p>
              </div>
            </a>

            <a
              to="/savedExercise"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "not-allowed",
              }}
              onClick={handleLimitedAccess}
            >
              <div
                className={`${
                  activePage === "savedExercise" ? "nav-btn active" : "nav-btn"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="17.778"
                  viewBox="0 0 20 17.778"
                  fill="#808080"
                >
                  <path
                    d="M15.833 2.614h-5.44a.857.857 0 0 1-.372-.084L7.39 1.254A2.579 2.579 0 0 0 6.273 1H4.167C1.869.999 0 2.811 0 5.039v9.697c0 2.228 1.87 4.04 4.167 4.04H5a.82.82 0 0 0 .833-.807A.82.82 0 0 0 5 17.16h-.833c-1.379 0-2.5-1.088-2.5-2.425V7.463h16.666v7.273c0 1.337-1.121 2.425-2.5 2.425H15a.82.82 0 0 0-.833.808.82.82 0 0 0 .833.808h.833c2.298 0 4.167-1.813 4.167-4.04V6.654c0-2.228-1.87-4.04-4.167-4.04ZM1.667 5.04c0-1.337 1.121-2.425 2.5-2.425h2.106c.129 0 .259.03.373.085l2.63 1.276c.346.167.732.255 1.117.255h5.44c1.085 0 2.002.678 2.347 1.616H1.667v-.808Zm11.422 10.59a.79.79 0 0 1 0 1.143l-1.344 1.303a2.499 2.499 0 0 1-1.745.7 2.497 2.497 0 0 1-1.744-.7L6.91 16.772a.79.79 0 0 1 0-1.143.85.85 0 0 1 1.178 0l1.078 1.045v-5.978A.82.82 0 0 1 10 9.888a.82.82 0 0 1 .833.808v5.978l1.078-1.045a.85.85 0 0 1 1.178 0Z"
                    fill-rule="evenodd"
                    data-name="folder-download"
                  />
                </svg>
                <p className="text">Saved Protocol</p>
              </div>
            </a>
            <a
              to="/myExercise"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "not-allowed",
              }}
              onClick={handleLimitedAccess}
            >
              <div
                className={`${
                  activePage === "myExercise" || activePage === "exerciseAdd"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.802"
                  height="20"
                  viewBox="18.545 7.5 14.802 20"
                >
                  <path
                    d="M25.946 27.5c-4.22 0-7.401-1.84-7.401-4.282 0-1.063.604-2.032 1.746-2.804a.471.471 0 0 1 .527.78c-.608.41-1.332 1.094-1.332 2.024 0 1.842 2.898 3.34 6.46 3.34s6.46-1.498 6.46-3.34c0-.93-.724-1.614-1.332-2.024a.47.47 0 0 1 .527-.78c1.143.772 1.746 1.741 1.746 2.804 0 2.441-3.181 4.282-7.4 4.282Zm0-2.08c-2.883 0-4.975-1.071-4.975-2.548 0-.502.249-1.435 1.917-2.057a.471.471 0 0 1 .329.881c-.83.31-1.305.738-1.305 1.176 0 .776 1.62 1.608 4.034 1.608 2.413 0 4.034-.832 4.034-1.608 0-.438-.475-.866-1.305-1.176a.47.47 0 0 1 .33-.881c1.667.622 1.916 1.555 1.916 2.057 0 1.477-2.092 2.549-4.975 2.549Zm0-1.385a2.206 2.206 0 0 1-2.203-2.203v-.7c0-.537-.361-1.356-.758-1.717v-.001c-.098-.09-.955-.918-.955-2.434 0-2.17 1.75-3.936 3.903-3.936a3.934 3.934 0 0 1 3.922 3.936c0 1.506-.85 2.34-.946 2.43-.399.364-.76 1.184-.76 1.721v.7a2.206 2.206 0 0 1-2.203 2.204ZM23.62 18.72c.587.536 1.064 1.618 1.064 2.412v.7c0 .696.566 1.262 1.262 1.262.696 0 1.262-.566 1.262-1.262v-.7c0-.795.476-1.878 1.062-2.414.024-.022.644-.61.644-1.738a2.992 2.992 0 0 0-2.982-2.995c-1.632 0-2.96 1.344-2.96 2.995 0 1.124.62 1.714.648 1.74Zm2.326-6.12a2.553 2.553 0 0 1-2.55-2.55 2.553 2.553 0 0 1 2.55-2.55 2.553 2.553 0 0 1 2.55 2.55 2.553 2.553 0 0 1-2.55 2.55Zm0-4.159a1.61 1.61 0 0 0-1.608 1.609 1.61 1.61 0 0 0 1.608 1.608 1.61 1.61 0 0 0 1.609-1.608 1.61 1.61 0 0 0-1.609-1.609Z"
                    fill="#808080"
                    fill-rule="evenodd"
                    data-name="Path 15624"
                  />
                </svg>
                <p className="text">My Exercise</p>
              </div>
            </a>
            <a
              to="/community"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "not-allowed",
              }}
              onClick={handleLimitedAccess}
            >
              <div
                className={`${
                  activePage === "community" || activePage === "AddBlog"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="49 638 17 17"
                >
                  <path
                    d="M57.5 649.333a2.833 2.833 0 1 1 0-5.666 2.833 2.833 0 0 1 0 5.666Zm0-4.25a1.417 1.417 0 1 0 0 2.834 1.417 1.417 0 0 0 0-2.834Zm4.25 9.209a4.25 4.25 0 1 0-8.5 0 .708.708 0 0 0 1.417 0 2.833 2.833 0 1 1 5.666 0 .708.708 0 0 0 1.417 0Zm0-10.625a2.833 2.833 0 1 1 0-5.667 2.833 2.833 0 0 1 0 5.667Zm0-4.25a1.417 1.417 0 1 0 0 2.833 1.417 1.417 0 0 0 0-2.833Zm4.25 9.208a4.254 4.254 0 0 0-4.25-4.25.708.708 0 1 0 0 1.417 2.833 2.833 0 0 1 2.833 2.833.708.708 0 0 0 1.417 0Zm-12.75-4.958a2.833 2.833 0 1 1 0-5.667 2.833 2.833 0 0 1 0 5.667Zm0-4.25a1.417 1.417 0 1 0 0 2.833 1.417 1.417 0 0 0 0-2.833Zm-2.833 9.208a2.833 2.833 0 0 1 2.833-2.833.708.708 0 0 0 0-1.417 4.254 4.254 0 0 0-4.25 4.25.708.708 0 0 0 1.417 0Z"
                    fill="#808080"
                    fill-rule="evenodd"
                    data-name="users-alt"
                  />
                </svg>
                <p className="text">Community</p>
              </div>
            </a>

            <a
              to="/appointmentCalendar"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleLimitedAccess}
            >
              <div
                className={`${
                  activePage === "appointmentCalendar"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.749"
                  height="15.749"
                  viewBox="0 0 15.749 15.749"
                >
                  <path
                    id="calendar-lines_1_"
                    data-name="calendar-lines (1)"
                    d="M12.468,1.312h-.656V.656a.656.656,0,1,0-1.312,0v.656H5.25V.656a.656.656,0,0,0-1.312,0v.656H3.281A3.285,3.285,0,0,0,0,4.593v7.874a3.285,3.285,0,0,0,3.281,3.281h9.187a3.285,3.285,0,0,0,3.281-3.281V4.593A3.285,3.285,0,0,0,12.468,1.312ZM3.281,2.625h9.187a1.971,1.971,0,0,1,1.969,1.969V5.25H1.312V4.593A1.971,1.971,0,0,1,3.281,2.625Zm9.187,11.812H3.281a1.971,1.971,0,0,1-1.969-1.969V6.562H14.436v5.906A1.971,1.971,0,0,1,12.468,14.436Zm0-5.25a.656.656,0,0,1-.656.656H3.937a.656.656,0,1,1,0-1.312h7.874A.656.656,0,0,1,12.468,9.187ZM7.874,11.812a.656.656,0,0,1-.656.656H3.937a.656.656,0,1,1,0-1.312H7.218A.656.656,0,0,1,7.874,11.812Z"
                    fill="#808080"
                  />
                </svg>
                <p className="text">Calendar</p>
              </div>
            </a>
            {/* {data?.associatedEntity === "Individual" && 
             user?.profileCompleted === true && */}

            {data?.credits === 0 && data?.address?.country === "India" && (
              <Link
                to="/creditPurchase"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div
                  className={`${
                    activePage === "creditPurchase"
                      ? "nav-btn active"
                      : "nav-btn"
                  }`}
                >
                  <img
                    src={
                      activePage === "creditPurchase" ? paymentActive : payment
                    }
                    alt=""
                    className={`icon ${
                      activePage === "creditPurchase" ? "heart" : ""
                    }`}
                  />
                  <p className="text">Payment</p>
                </div>
              </Link>
            )}

            {data?.credits === 0 && data?.address?.country !== "India" && (
              <Link
                to="/paymentStripe"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div
                  className={`${
                    activePage === "paymentStripe"
                      ? "nav-btn active"
                      : "nav-btn"
                  }`}
                >
                  <img
                    src={
                      activePage === "paymentStripe" ? paymentActive : payment
                    }
                    alt=""
                    className={`icon ${
                      activePage === "paymentStripe" ? "heart" : ""
                    }`}
                  />
                  <p className="text">Payment</p>
                </div>
              </Link>
            )}
            {/* } */}
          </div>
          {/* <div className="bottom-logoutpart">
          <button className='logout-btn' onClick={() => handleLogout()}>
            <img src={logout} alt="logout-icon" className='icon' />
            <p className='text'>Logout</p>
          </button>
        </div> */}
        </div>
      </div>
      <ProfileCompletionModal
        show={showProfileCompletionModal}
        onHide={() => setShowProfileCompletionModal(false)}
        From={"Add Patient"}
      />

      <ClinicVerficationModal
        show={showClinicModal}
        onHide={() => setShowClinicModal(false)}
      />
      <LimitedAccessModal
        show={LimitedAccessModalstate}
        onHide={() => setLimitedAccessModalstate(false)}
      />
    </>
  );
};

export default PublicSidebar;
