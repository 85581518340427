import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PublicLayout from "../../layout/PublicLayout";
import LikeComment from "../../assets/images/LikeCommunity.svg";
import SearchIcon from "../../assets/images/search.svg";
import FilterIcon from "../../assets/images/filter.svg";
import { get } from "../../libs/http-hydrate";
import PageLink from "../../components/PageLink";
import { AiOutlineArrowLeft } from "react-icons/ai";

function ExerciseView() {
  const navigate = useNavigate();
  const location = useLocation();
  const { title: rawTitle } = useParams(); // Extract raw title from URL

  const [selectedEx, setSelectedEx] = useState(location?.state?.exercise || null);
  const [loading, setLoading] = useState(!location?.state?.exercise);
  const [error, setError] = useState(null);

  // Format title from URL
  const formattedTitle = rawTitle.replace(/-/g, " ");

  // Fetch exercise if not passed via location state
  useEffect(() => {
    if (!selectedEx) {
      const fetchExercise = async () => {
        try {
          setLoading(true);
          const response = await get(`/exercise-view?title=${formattedTitle}`);
          if ( response.data.data.length > 0) {
            setSelectedEx(response.data.data[0]); // Assuming you're using the first match
          } else {
            setError("No exercise found.");
          }
        } catch (err) {
          setError("Failed to fetch exercise. Please try again.");
        } finally {
          setLoading(false);
        }
      };

      fetchExercise();
    }
  }, [formattedTitle, selectedEx]);

  // Extract target area
  const targetArea = selectedEx?.body_part_id
    ?.map((item) => item?.title)
    ?.join(", ") || "general fitness";

  // Generate dynamic meta content
  const metaTitle = `${selectedEx?.title || formattedTitle} - VirtueLife Physiotherapy`;
  const metaDescription = `Learn how to perform ${selectedEx?.title || formattedTitle} to improve ${
    targetArea || "your fitness"
  } with our step-by-step guide.`;
  const metaKeywords = `${selectedEx?.title || formattedTitle}, ${
    targetArea || "exercise"
  } exercise, physiotherapy exercises, VirtueLife`;

  if (loading) {
    return (
      <PublicLayout>
        <div className="loader text-center" style={{ marginTop: "20%" }}>
          <p>Loading...</p>
        </div>
      </PublicLayout>
    );
  }

  if (error) {
    return (
      <PublicLayout>
        <div className="error-message text-center" style={{ marginTop: "20%" }}>
          <p>{error}</p>
          <button onClick={() => navigate(-1)} className="btn btn-primary">
            Go Back
          </button>
        </div>
      </PublicLayout>
    );
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>

      <PublicLayout>
        <div className="topBar" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <div className="theraphy-name d-flex align-items-center justify-content-between w-100 ">
            <div className="serchPart d-flex justify-content-start w-50 align-items-center ">
              <div
                className="back-button"
                style={{ marginRight: "1rem", cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/exercise-library");
                }}
              >
                <AiOutlineArrowLeft />
              </div>
              <div className="searchInput m-0">
                <div className="searchIcon" style={{ top: "8px", left: "15px" }}>
                  <img src={SearchIcon} alt="searchIcon" height={20} width={20} />
                </div>
                <input
                  type="text"
                  placeholder="Search exercises"
                  onFocus={() => {
                    navigate("/exercise-library");
                  }}
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "14px",
                    paddingLeft: "40px",
                  }}
                />
              </div>
              <div className="px-2">
                <button
                  className="btn border mx-2 d-flex align-items-center"
                  style={{
                    borderRadius: "46px",
                    fontSize: "14px",
                    width: "max-content",
                  }}
                >
                  <img src={FilterIcon} alt="filterIcon" height={15} width={15} />
                  &nbsp; Filter
                </button>
              </div>
              <div>
                <PageLink />
              </div>
            </div>
          </div>
        </div>
        <div className="main-content-part foot-page p-0 bg-white px-5">
          <div className="d-flex justify-content-between">
            <span className="name p-1" style={{ fontSize: "18px" }}>
              {selectedEx?.title}
            </span>

            <button
              className="selectedExeBtn d-block text-center hide-on-mobile"
              style={{
                backgroundColor: "#FDF5F2",
                borderRadius: "7px",
                borderColor: "#FDF5F2",
                minWidth: "150px",
                maxWidth: "150px",
                fontSize: "15px",
                lineHeight: "40px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                padding: "0",
                border: "1px solid #ff603600",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              + Assign
            </button>
          </div>
          <hr />
          <div className="row p-3">
            <div className="mt-4 col-md-5">
              <div className="video p-2" style={{ border: "1px solid #efeded" }}>
                <div
                  style={{
                    borderRadius: "14px",
                    display: "block",
                    width: "100%",
                    height: "250px",
                  }}
                >
                  <video
                    preload="auto"
                    controls={true}
                    src={selectedEx?.videoLink}
                    style={{ width: "100%", height: "100%" }}
                  ></video>
                </div>
              </div>
            </div>
            <div className="col-md-7 p-3">
              <div className="text-part text-start mt-2">
                <div className="">
                  <span className="label m-2">Equipment:</span>
                  <br />
                  <span className="data my-3 mx-2">
                    {selectedEx?.equipment?.length > 0
                      ? selectedEx.equipment.join(", ")
                      : "No Equipment"}
                  </span>
                </div>
                <div className="">
                  <span className="label m-2">BodyPart:</span>
                  <br />
                  <span className="data mx-2 my-3">
                    {selectedEx?.body_part_id?.map((item, ind) => (
                      <React.Fragment key={ind}>
                        {ind > 0 && ", "}
                        {item?.title}
                      </React.Fragment>
                    ))}
                  </span>
                </div>
                <div className="">
                  <span className="label m-2">Description:</span>
                  <br />
                  <div className="data mx-2 my-3">
                    {selectedEx?.description?.split(".")
                      .filter((sentence) => sentence.trim() !== "")
                      .map((sentence, index) => (
                        <React.Fragment key={index}>
                          <span className="">{index + 1}.</span>
                          <span className="exerciseLabfontSUB name p-1 pb-2">
                            {sentence.trim()}.
                          </span>
                          <br />
                          <br />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PublicLayout>
    </>
  );
}

export default ExerciseView;
