import React, { useCallback, useEffect, useRef, useState } from "react";
import "../assets/css/header.css";
import { BsSearch } from "react-icons/bs";
import notificationIcon from "../assets/images/notification.svg";
import Avatar from "react-avatar";
import profileImg from "../assets/images/Ellipse 179.svg";
import { Dropdown, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Auth from "../libs/auth";
import { get, getAuthConfig } from "../libs/http-hydrate";
import DoctorDummy from "../assets/images/defaultdoctor@3x.png";
import Patient from "../assets/images/defaultpatient@3x.png";
import TrustIcon from "../assets/images/TRUST ICON.png";
import moment from "moment";
import FillStar from "../assets/images/star.svg";
import { AiOutlineArrowLeft } from "react-icons/ai"; // Importing back arrow icon from react-icons
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../assets/images/Virtue Life logo1.svg";
// import logo from "../assets/images/RecureMe.svg";
import logout from "../assets/images/signOut.svg";
import home from "../assets/images/home.svg";
import patient from "../assets/images/patients.svg";
import point from "../assets/images/point.svg";
import homeActive from "../assets/images/homeActive.svg";
import pointActive from "../assets/images/pointActive.svg";
import heartSvg from "../assets/images/heartBlack.svg";
import heartFilled from "../assets/images/heartFilled.svg";
import patientActive from "../assets/images/patientActive.svg";
import folderSvg from "../assets/images/folder-download.svg";
import folderSvgBlack from "../assets/images/folderBlack.svg";
import MyExercise from "../assets/images/myExercise.svg";
import MyExerciseFill from "../assets/images/myExerciseFill.svg";
import CommunitySidebar from "../assets/images/communitySidebad.svg";
import CommunityFillSidebar from "../assets/images/communityFillSidebar.svg";
import ProfileCompletionModal from "../pages/AddProfileData/ProfileCompletionModal";
import PatientDummy from "../assets/images/defaultpatient@3x.png";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DoctorCommon from "../assets/images/Docotr.png";
import DoctorMale from "../assets/images/Doctor Male.png";
import DoctorFeMale from "../assets/images/Doctor Female.png";
import PatientCommon from "../assets/images/Patint.png";
import PatientFemale from "../assets/images/Patient Female.png";
import PatientMale from "../assets/images/Patient male.png";
import InternetConnectivity from "./InternetConnectivity";
import payment from "../assets/images/payment.svg";
import paymentActive from "../assets/images/paymentActive.svg";
import DaysTrailModal from "./DaysTrailModal";
import Over7DaysTrialModal from "./Over7DaysTrialModal";
import LimitedAccessModal from "./LimitedAccessModal";

const PublicHeader = () => {
  const [loading, setLoading] = useState(false); // Add this line
  const [showOver7DaysTrialModal, setShowOver7DaysTrialModal] = useState(false);
  const location = useLocation();
  const [LimitedAccessModalstate, setLimitedAccessModalstate] = useState(false);
  const activePage = location.pathname.replace("/", "");
  const navigate = useNavigate();
  const pointsActivePaths = [
    "points",
    "exerciseLab",
    "surgeryTemplate",
    "createExercise",
  ];
  const [chatCount, setChatCount] = useState(0);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });
  const shouldShowSidebar =
    location.pathname == "/payment" ||
    location.pathname == "/paymentForm" ||
    location.pathname == "/myPlanDetails" ||
    location.pathname == "/creditPurchase" ||
    location.pathname == "/paymentStripe" ||
    location.pathname == "/paymentFormStripe";
  const handleBack = () => {
    // Implement the back functionality here
    // For example, using history from react-router
    // navigate(props?.backlink)
    navigate(-1);
  };
  const handleLimitedAccess = () => {
    setLimitedAccessModalstate(true);
  };
  const shouldShowOver7DaysTrialModal = () => {
    const excludedPaths = [
      "/payment",
      "/paymentForm",
      "/myPlanDetails",
      "/creditPurchase",
      "/paymentStripe",
      "/paymentFormStripe",
    ];
    return (
      !excludedPaths.includes(location.pathname) && showOver7DaysTrialModal
    );
  };

  const dropdownRef = useRef(null);
  const [show, setShow] = useState(false);
  const [wlcmModal, setWlcmModal] = useState(false);
  const handleClose = () => {
    getHomeData();
    setShow(false);
  };

  const handleShow = () => setShow(true);
  const [headerData, setHeaderData] = useState(() => {
    // Fetch data from localStorage
    const storedData = localStorage.getItem("notificationData");

    // Parse JSON data if available, otherwise return an empty object
    return storedData ? JSON.parse(storedData) : {};
  });
  const [page, setPage] = useState(0);
  const [notification, setNotification] = useState({
    items: [],
    has_more: false,
  });
  const [unreadCount, setunreadCount] = useState();
  const [doctorList, setDoctorList] = useState({
    items: [],
    has_more: false,
  });
  const [docpage, setDocPage] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const user = Auth.getCurrentUser();
  const [showInternetModal, setShowInternetModal] = useState(false);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && notification?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [notification?.has_more]
  );
  function formatDateTime(timestamp) {
    const time = moment(timestamp).format("h:mm A"); // 5:45 PM
    const date = moment(timestamp).format("MMM D"); // Feb 24
    return { time, date };
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  async function getHomeData() {
    if (!navigator.onLine) {
      setShowInternetModal(true);
      return;
    }

    localStorage.removeItem("notificationData");

    try {
      const data = await get("/physiotherapist", getAuthConfig());
      if (data?.status === 200) {
        //setHeaderData(data?.data?.doc);

        const docData = data?.data?.doc;
        const createdAtToday = moment(docData?.created_at).isSame(
          moment(),
          "day"
        );

        // Check if the account is 7 days old
        const accountCreationDate = moment(docData?.created_at);
        const todayDate = moment();
        const daysSinceCreation = todayDate.diff(accountCreationDate, "days");

        if (!headerData?.name) {
          // If headerData is empty, set it to the new data
          setHeaderData(data?.data?.doc);
        } else if (!compareObjects(data?.data?.doc, headerData)) {
          // If headerData is not empty and the new data is different, update headerData
          setHeaderData(data?.data?.doc);
        }

        // if(!data?.data?.doc?.years_of_experience)
        // {
        //   Auth.logout()
        //   navigate("/login")
        // }

        if (data?.data?.doc) {
          localStorage.setItem(
            "notificationData",
            JSON.stringify(data.data.doc)
          );
        }
        if (data?.data?.unread_notification_count != null) {
          setunreadCount(data?.data?.unread_notification_count);
        }

        if (data?.data?.unread_chat_count != null) {
          setChatCount(data?.data?.unread_chat_count);
        }

        // Check conditions for showing the DaysTrailModal
        // const hasShownDaysTrial = localStorage.getItem("hasShownDaysTrial");
        // if (docData?.profileCompleted === false && createdAtToday && !hasShownDaysTrial) {
        //     setWlcmModal(true);
        //     localStorage.setItem("hasShownDaysTrial", "true");
        // }

        // Show Over7DaysTrialModal if account is 7 days old and subscriptionId is not present
        // console.log(daysSinceCreation,"daysSinceCreationdaysSinceCreation",docData?.subscriptionId)
        // if (daysSinceCreation === 7 && !docData?.subscriptionId) {
        //   setShowOver7DaysTrialModal(true);
        // }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }
  // Function to compare two objects
  const compareObjects = (obj1, obj2) => {
    // Compare objects based on specific properties
    console.log(
      "obj1:",
      obj1.email,
      obj1.gender,
      obj1.mobile,
      obj1.name,
      obj1.profileCompleted,
      obj1.profile_image,
      obj1.token,
      obj1._id,
      obj1.clinicVerified
    );
    console.log(
      "obj2:",
      obj2.email,
      obj2.gender,
      obj2.mobile,
      obj2.name,
      obj2.profileCompleted,
      obj2.profile_image,
      obj2.token,
      obj2._id,
      obj2.clinicVerified
    );
    return (
      obj1.email === obj2.email &&
      obj1.gender === obj2.gender &&
      obj1.mobile === obj2.mobile &&
      obj1.name === obj2.name &&
      obj1.profileCompleted === obj2.profileCompleted &&
      obj1.profile_image === obj2.profile_image &&
      obj1.token === obj2.token &&
      obj1._id === obj2._id &&
      obj1.clinicVerified === obj2.clinicVerified
    );
  };

  async function getNotification() {
    try {
      const data = await get(
        `/notification?pageNumber=${page}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        let sortedItems = data?.data?.msg;

        // Sort the notifications by 'created_at' timestamp in descending order
        sortedItems.sort((a, b) => {
          const timestampA = new Date(a.created_at).getTime();
          const timestampB = new Date(b.created_at).getTime();
          return timestampB - timestampA;
        });

        if (page > 0) {
          setNotification((prevNotification) => ({
            ...prevNotification,
            items: prevNotification?.items.concat(sortedItems),
          }));
        } else {
          setNotification((prevNotification) => ({
            ...prevNotification,
            items: sortedItems,
          }));
        }
        // setDoctorList((e) => ({ ...e, items: data?.data?.data }))
        setNotification((e) => ({ ...e, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }

  async function getPatientList() {
    setLoading(true); // Start loading
    try {
      const data = await get(
        `/patientList?pageNumber=${docpage}&searchTerm=${searchTerm}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        if (docpage > 0) {
          setDoctorList((p) => ({
            ...p,
            items: doctorList?.items.concat(data?.data?.data),
          }));
        } else {
          setDoctorList((p) => ({ ...p, items: data?.data?.data }));
        }
        // setDoctorList((e) => ({ ...e, items: data?.data?.data }))
        setDoctorList((e) => ({ ...e, has_more: data?.data?.has_more }));
      }
    } catch (error) {
    } finally {
      setLoading(false); // Stop loading
    }
  }
  const [showProfileCompletionModal, setShowProfileCompletionModal] =
    useState(false);

  const handleAddPatientClick = () => {
    // if (user?.profileCompleted === false) {
    //   setShowProfileCompletionModal(true);
    // } else {
    navigate("/personalInformation");
    // }
  };

  return (
    <>
      {/* <div className="header-main"> */}
      {shouldShowSidebar && (
        <button className="back btn " onClick={handleBack}>
          <AiOutlineArrowLeft />
        </button>
      )}

      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="white"
        className="w-100 header-main"
      >
        <Navbar.Brand onClick={() => navigate("/")} className="cursor-pointer">
          <div className="w-100 text-center mobile-logo">
            <img src={logo} alt="docpy-logo" width={180} height={50} />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav "
          style={{ backgroundColor: "white" }}
        >
          <Nav className="mr-auto w-100">
            <div className="sidebar-main" style={{ height: "fit-content" }}>
              <div className="nav-part">
                <button
                  className="btn btn-add mb-3"
                  onClick={handleAddPatientClick}
                >
                  + Add Patient
                </button>

                <div className="top-navpart">
                  <Link
                    to="/dashboard"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={` ${
                        activePage === "dashboard" ||
                        activePage === "doctorList"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "dashboard" ||
                          activePage === "doctorList"
                            ? homeActive
                            : home
                        }
                        alt=""
                        className="icon"
                      />
                      <p className="text">Home</p>
                    </div>
                  </Link>
                  <Link
                    to="/allPatient"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "allPatient" ||
                        activePage === "patientDetails" ||
                        activePage === "history"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "allPatient" ||
                          activePage === "patientDetails" ||
                          activePage === "history"
                            ? patientActive
                            : patient
                        }
                        alt=""
                        className="icon"
                      />
                      <p className="text">Patient</p>
                    </div>
                  </Link>
                  <Link
                    to="/points"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        pointsActivePaths.includes(activePage)
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          pointsActivePaths.includes(activePage)
                            ? pointActive
                            : point
                        }
                        alt=""
                        className="icon"
                      />
                      <p className="text">Library</p>
                    </div>
                  </Link>
                  <Link
                    to="/selectedExercise"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "selectedExercise"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "selectedExercise"
                            ? heartFilled
                            : heartSvg
                        }
                        alt=""
                        className={`icon`}
                      />
                      <p className="text">Saved Exercise</p>
                    </div>
                  </Link>

                  <Link
                    to="/savedExercise"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "savedExercise"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "savedExercise"
                            ? folderSvg
                            : folderSvgBlack
                        }
                        alt=""
                        className={`icon ${
                          activePage === "savedExercise" ? "heart" : ""
                        }`}
                      />
                      <p className="text">Saved Protocol</p>
                    </div>
                  </Link>
                  <Link
                    to="/myExercise"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "myExercise" ||
                        activePage === "exerciseAdd"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "myExercise" ||
                          activePage === "exerciseAdd"
                            ? MyExercise
                            : MyExerciseFill
                        }
                        alt=""
                        className={`icon ${
                          activePage === "myExercise" ? "heart" : ""
                        }`}
                      />
                      <p className="text">My Exercise</p>
                    </div>
                  </Link>
                  <Link
                    to="/community"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "community" || activePage === "AddBlog"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "community" || activePage === "AddBlog"
                            ? CommunityFillSidebar
                            : CommunitySidebar
                        }
                        alt=""
                        className={`icon ${
                          activePage === "community" ? "heart" : ""
                        }`}
                      />
                      <p className="text">Community</p>
                    </div>
                  </Link>

                  {data?.credits === 0 &&
                    data?.address?.country === "India" && (
                      <Link
                        to="/creditPurchase"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div
                          className={`${
                            activePage === "creditPurchase"
                              ? "nav-btn active"
                              : "nav-btn"
                          }`}
                        >
                          <img
                            src={
                              activePage === "creditPurchase"
                                ? paymentActive
                                : payment
                            }
                            alt=""
                            className={`icon ${
                              activePage === "creditPurchase" ? "heart" : ""
                            }`}
                          />
                          <p className="text">Payment</p>
                        </div>
                      </Link>
                    )}

                  {data?.credits === 0 &&
                    data?.address?.country !== "India" && (
                      <Link
                        to="/paymentStripe"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div
                          className={`${
                            activePage === "paymentStripe"
                              ? "nav-btn active"
                              : "nav-btn"
                          }`}
                        >
                          <img
                            src={
                              activePage === "paymentStripe"
                                ? paymentActive
                                : payment
                            }
                            alt=""
                            className={`icon ${
                              activePage === "payment" ? "heart" : ""
                            }`}
                          />
                          <p className="text">Payment</p>
                        </div>
                      </Link>
                    )}
                  {/* <Link
            to='/payment'
            style={{ textDecoration: "none", color: "inherit" }}>
            <div className={`${activePage === 'payment' ? 'nav-btn active' : 'nav-btn'}`}>
              <img src={activePage === 'payment' ? paymentActive :  payment }  alt="" className={`icon ${activePage === 'payment' ? "heart" : ""}`}/>
              <p className='text'>Payment</p>
            </div>
          </Link> */}
                  <div
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={() => handleLogout()}
                  >
                    <div className={`nav-btn`}>
                      <img src={logout} alt="" className={`icon`} />
                      <p className="text">Logout</p>
                    </div>
                  </div>
                </div>
                {/* <div className="bottom-logoutpart">
          <button className='logout-btn' onClick={() => handleLogout()}>
            <img src={logout} alt="logout-icon" className='icon' />
            <p className='text'>Logout</p>
          </button>
        </div> */}
              </div>
            </div>

            {/* Place your navigation links here */}
            <div className="search-part w-50">
              <input
                type="text"
                placeholder="search patients"
                value={searchTerm}
                disabled
                onChange={(e) => {
                  e.preventDefault();
                  setSearchTerm(e.target.value);
                  setDropdownOpen(true);
                }}
                onFocus={handleLimitedAccess}
              />
              <BsSearch size={16} className="icon-search" />
            </div>
            {isDropdownOpen && (
              <div className="dropdowns" style={{ width: "30%" }}>
                <div
                  className="dropdowns-list"
                  style={{
                    maxHeight: "55vh",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <div className="doctors-list" ref={dropdownRef}>
                    {/* Your dropdown content goes here */}
                    <div
                      className="title-block"
                      style={{ display: "inline-block", width: "100%" }}
                    >
                      <Row className="g-1 my-1 mx-1">
                        {loading ? (
                          <div className="text-center p-5">
                            <Spinner
                              animation="border"
                              style={{ color: "black" }} // Custom color for the spinner
                            />{" "}
                            {/* Display loading spinner */}
                          </div>
                        ) : (
                          doctorList?.items &&
                          doctorList?.items.length > 0 &&
                          doctorList?.items?.map((item, index) => {
                            const istTimestamp = moment(item?.created_at)
                              .utcOffset("+05:30")
                              .format("YYYY-MM-DD HH:mm:ss");

                            return (
                              <Col lg="12">
                                <Link
                                  to="/patientDetails"
                                  state={{ patient: item }}
                                  className="linkCard"
                                >
                                  <div
                                    className="doctor-card d-flex align-items-center"
                                    style={{
                                      "border-radius": "4px",
                                      padding: "5px",
                                      "box-shadow": "0px 1px 15px #0000000f",
                                    }}
                                  >
                                    <div
                                      className="image-part "
                                      style={{
                                        width: "max-content",
                                        height: "max-content",
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.profile_image
                                            ? item?.profile_image
                                            : item?.gender === "female"
                                            ? PatientFemale
                                            : item?.gender === "male"
                                            ? PatientMale
                                            : PatientCommon
                                        }
                                        height={30}
                                        width={30}
                                        alt="Patient"
                                        className="rounded-circle"
                                      />
                                    </div>
                                    <div className="text-part w-100 d-flex justify-content-between align-items-center">
                                      <h5
                                        className=""
                                        style={{
                                          fontSize: "13px",
                                          marginLeft: "10px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {item?.name}
                                      </h5>
                                      <p style={{ marginBottom: "0px" }}>
                                        <span style={{ fontSize: "12px" }}>
                                          {moment(istTimestamp).format(
                                            "h:mm A"
                                          )}{" "}
                                        </span>
                                        <span
                                          style={{
                                            color: "#ADADAD",
                                            fontSize: "11px",
                                          }}
                                        >
                                          {" "}
                                          {moment(item?.created_at).format(
                                            "MMM DD"
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>{" "}
                                </Link>
                              </Col>
                            );
                          })
                        )}

                        {doctorList?.has_more === true && (
                          <div
                            className="text-center p-3"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setDocPage(
                                (prevPageNumber) => prevPageNumber + 1
                              );
                            }}
                          >
                            Load More
                          </div>
                        )}
                        {doctorList?.items && doctorList?.items.length <= 0 && (
                          <div className="text-center p-5">
                            No Patient Found
                          </div>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <LinkContainer to="/dashboard">
              <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer> */}
            {/* ... other navigation links */}
          </Nav>
          <Nav className="w-100">
            {/* Right-aligned items (e.g., profile, notifications) */}
            {/* <LinkContainer to="/profile">
                <NavDropdown.Item>Profile</NavDropdown.Item>
              </LinkContainer> */}

            <div className="profile-part">
              <div
                className="notification-block"
                style={{ cursor: "pointer" }}
                onClick={handleLimitedAccess}
              >
                <svg
                  width="25"
                  height="21"
                  viewBox="0 0 49 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.1818 13.7143H36.7727M10.1818 21.5238H28.5909M10.1818 2H38.8182C40.9881 2 43.0692 2.82279 44.6036 4.28736C46.138 5.75193 47 7.73831 47 9.80952V25.4286C47 27.4998 46.138 29.4862 44.6036 30.9507C43.0692 32.4153 40.9881 33.2381 38.8182 33.2381H14.2727L5.24501 40.4189C3.93459 41.4612 2 40.528 2 38.8536V9.80952C2 7.73831 2.86201 5.75193 4.3964 4.28736C5.93079 2.82279 8.01187 2 10.1818 2Z"
                    stroke="#808080"
                    stroke-width="4"
                  />
                </svg>

                {Number(chatCount) != 0 && (
                  <div className="noti-no">
                    {Number(chatCount) > 99 ? "99+" : chatCount}
                  </div>
                )}
              </div>

              <div
                className="notification-block"
                style={{ cursor: "pointer" }}
                onClick={handleLimitedAccess}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="22"
                  viewBox="0 0 27 29.793"
                >
                  <path
                    id="bell"
                    d="M27.758,16.957,25.4,8.472A11.57,11.57,0,0,0,2.96,9.06L1.134,17.271a6.206,6.206,0,0,0,6.058,7.553H8.575a6.206,6.206,0,0,0,12.164,0h1.04a6.206,6.206,0,0,0,5.98-7.867Zm-13.1,10.349a3.724,3.724,0,0,1-3.5-2.482h6.991a3.724,3.724,0,0,1-3.5,2.482Zm10.086-6.436a3.7,3.7,0,0,1-2.965,1.471H7.192A3.724,3.724,0,0,1,3.558,17.81L5.382,9.6a9.087,9.087,0,0,1,17.626-.462l2.358,8.485a3.7,3.7,0,0,1-.623,3.25Z"
                    transform="translate(-0.986 -0.002)"
                    fill="#808080"
                  />
                </svg>

                {Number(unreadCount) != 0 && (
                  <div className="noti-no">
                    {Number(unreadCount) > 99 ? "99+" : unreadCount}
                  </div>
                )}
              </div>

              <div className="profile">
                {user?.profile_image ? (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={user?.profile_image}
                      style={{ borderRadius: "55%" }}
                      height={"40px"}
                      width={"40px"}
                      alt="User Profile"
                    />
                    {headerData?.clinicVerified === true && ( // Check if the user is verified
                      <div
                        style={{
                          position: "absolute",
                          top: "-2px",
                          right: "-5px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <circle cx="10" cy="10" r="10" fill="green" />
                          <path
                            d="M7 10 L9 12 L14 7"
                            stroke="white"
                            stroke-width="2"
                            fill="transparent"
                          />
                        </svg>

                        {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={
                        user?.gender === "female"
                          ? DoctorFeMale
                          : user?.gender === "male"
                          ? DoctorMale
                          : DoctorCommon
                      }
                      style={{ borderRadius: "55%" }}
                      height={"40px"}
                      width={"40px"}
                      alt="User Profile"
                    />
                    {headerData?.clinicVerified === true && ( // Check if the user is verified
                      <div
                        style={{
                          position: "absolute",
                          top: "-2px",
                          right: "-5px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <circle cx="10" cy="10" r="10" fill="green" />
                          <path
                            d="M7 10 L9 12 L14 7"
                            stroke="white"
                            stroke-width="2"
                            fill="transparent"
                          />
                        </svg>

                        {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                      </div>
                    )}
                  </div>
                )}
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-profile" className="btn-custom">
                    {user?.name ?  user?.name  : "Angle"}{" "}
                  </Dropdown.Toggle>
                  {/* <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        navigate("/doctorDetailsForm");
                      }}
                    >
                      Account
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        navigate("/allPatient");
                      }}
                    >
                      Patients{" "}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        Auth.logout();
                        navigate("/login");
                      }}
                    >
                      Logout{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </div>
            {/* ... other dropdown items */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ProfileCompletionModal
        show={showProfileCompletionModal}
        onHide={() => setShowProfileCompletionModal(false)}
        From={"Add Patient"}
      />
      {/* </div> */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="notication-modal"
      >
        <Modal.Header
          closeButton
          className="border-0"
          style={{
            justifyContent: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Modal.Title className="text-left">Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content" ref={lastBookElementRef}>
            {notification?.items && notification?.items.length <= 0 && (
              <div className="text-center"> No Records Found </div>
            )}

            {notification?.items &&
              notification?.items.length > 0 &&
              notification?.items.map((item, index) => {
                return (
                  <>
                    <div
                      className="card p-3 my-2"
                      style={{
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (item?.type === "Workout" && item?.mentionedUser) {
                          navigate("/history", {
                            state: { patient_id: { _id: item?.mentionedUser } },
                          });
                        }
                      }}
                    >
                      <p className="d-flex align-items-center justify-content-between">
                        <div>
                          {item?.isRead === false && (
                            <span
                              style={{
                                content: "",
                                display: "inline-block",
                                width: "10px", // Size of the circle
                                height: "10px", // Size of the circle
                                borderRadius: "50%", // Makes it rounded
                                backgroundColor: "#FE6036", // Color of the circle
                                //position: "absolute", // Position relative to the card
                                marginRight: "5px",
                              }}
                            ></span>
                          )}
                          <strong style={{ fontSize: "14px" }}>
                            {item?.title}
                          </strong>
                        </div>
                        <p>
                          <span style={{ fontWeight: "400", fontSize: "13px" }}>
                            {formatDateTime(item?.created_at).time}
                          </span>{" "}
                          <span style={{ color: "#999", fontSize: "12px" }}>
                            {formatDateTime(item?.created_at).date}
                          </span>
                        </p>
                      </p>
                      <p
                        className="light-text mt-1"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.body}
                      </p>
                    </div>
                  </>
                );
              })}
          </div>
        </Modal.Body>
      </Modal>
      <InternetConnectivity
        show={showInternetModal}
        onHide={() => setShowInternetModal(false)}
      />

      <DaysTrailModal show={wlcmModal} onHide={() => setWlcmModal(false)} />
      <Over7DaysTrialModal
        show={shouldShowOver7DaysTrialModal()}
        onHide={() => setShowOver7DaysTrialModal(false)}
        data={data}
      />
      <LimitedAccessModal
        show={LimitedAccessModalstate}
        onHide={() => setLimitedAccessModalstate(false)}
      />
    </>
  );
};

export default PublicHeader;
