import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Timer from "../assets/images/Timer.png";
import { useNavigate } from "react-router-dom";
const LimitedAccessModal = ({ show, onHide ,msg }) => {
  const navigate = useNavigate()
  return (
    <>
      <Modal show={show} onHide={onHide} centered className="limitedmodel">
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <div className="text-center w-75">
            <img src={Timer} height={100} />
            <br />
            <span className="mt-2" style={{ fontSize: "18px" }}>
              <strong> {msg? msg :'Your access limit has been reached'} </strong>
            </span>
            <br />
            <span className="mt-2" style={{ fontSize: "14px" }}>
              Register Now to Unlock The Full Library And Exclusive Features!
            </span>
            <br /> <br />
            <button
              className="theme-button btn btn-primary"
              style={{
                minWidth: "170px",
                padding: "10px",
                borderRadius: "9px",
              }}
              onClick={(e) =>{
                navigate("/")
              }}
            >
              Register Now
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LimitedAccessModal;
