import { Button, Col, Form, Row } from "react-bootstrap";
import profileUpload from "../../assets/images/upload.svg";
import "../../assets/css/profile.css";
import { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  get,
  getAuthConfig,
  getAuthConfigImage,
  post,
  deletes,
  put,
} from "../../libs/http-hydrate";
import { useLocation, useNavigate } from "react-router-dom";
import Auth from "../../libs/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import GeoLocation from "../../common/GeoLocation";
import FieldValidationError from "../../common/FieldValidation";
import Select from "react-select";
import { useLoadScript } from "@react-google-maps/api";
import PlacesAutoComplete from "../AutoComplete";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import BackIcon from "../../assets/images/left.svg";
import PremiumUserSwitchModal from "../../components/PremiumUserSwitchModal";
import ConfirmEmailLoginModal from "../../components/ConfirmEmailLoginModal";
import EmailLoginModal from "../../components/EmailLoginModal";
import OtpModal from "../../components/OtpModal";
import MobileConfirmModal from "../../components/MobileConfirmModal"
import CurrentPasswordModal from "../../components/CurrentPasswordModal"
function AddProfileData() {
  const [confirmEmailError, setConfirmEmailError] = useState(""); // For errors in ConfirmEmailLoginModal
  const [confirmEmailSuccess, setConfirmEmailSuccess] = useState(""); // For success in ConfirmEmailLoginModal

  const [otpError, setOtpError] = useState(""); // For errors in OtpModal
  const [otpSuccess, setOtpSuccess] = useState(""); // For success in OtpModal

  const [emailLoginError, setEmailLoginError] = useState(""); // For errors in EmailLoginModal
  const [emailLoginSuccess, setEmailLoginSuccess] = useState(""); // For success in EmailLoginModal
  const [otploading, setOTPLoading] = useState(false);  // State to manage loading

  const [isNewMobileAdded, setIsNewMobileAdded] = useState(false);
  const [showMobileConfirmModal, setShowMobileConfirmModal] = useState(false);
  const [showCurrentPasswordModal, setShowCurrentPasswordModal] = useState(false);
  const [userMobileChanged, setUserMobileChanged] = useState(false);
  const [passwordModalError, setPasswordModalError] = useState(""); // For error messages in password modal
  const [passwordModalSuccess, setPasswordModalSuccess] = useState(""); // For success messages in password modal
  const user = Auth.getCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({
    currency: "",
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDgERv1vmBkeZ0zWVMFRUksVmYao3v5vms",
    libraries: ["places"],
  });
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const [clinicImagePreview, setClinicImagePreview] = useState("");
  const [locations, setLocation] = useState(null);
  const clinicInputRef = useRef(null);
  const profileInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const confirmPasswordInputRef = useRef(null);
  const associatedInputRef = useRef(null);
  const yearsOfExperienceInputRef = useRef(null);
  const specialityInputRef = useRef(null);
  const availabilityInputRef = useRef(null);
  const genderInputRef = useRef(null);
  const briefDetailInputRef = useRef(null);
  const feesInputRef = useRef(null);
  const hospitalNameInputRef = useRef(null);
  const hospitalclinicPhoneRef = useRef(null);
  const hospitalhospital_emailRef = useRef(null);
  const hospitalFeesInputRef = useRef(null);
  const hospitalAddressInputRef = useRef(null);
  const streetInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const stateInputRef = useRef(null);
  const countryInputRef = useRef(null);
  const zipInputRef = useRef(null);
  const gstNumberRef = useRef(null);
  const localStreetInputRef = useRef(null);
  const companyNameRef = useRef(null);
  const [country, setCountry] = useState("");
  let [state, setstate] = useState("");
  let [city, setCity] = useState("");
  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState({});
  const [clinicError, setClinicError] = useState("");
  const options = [
    { value: "USD", icon: "🇺🇸", text: "US dollar" },
    { value: "EUR", icon: "🇪🇺", text: "Euro" },
    { value: "INR", icon: "🇮🇳", text: "Indian rupee" },
    { value: "CAD", icon: "🇨🇦", text: "Canadian dollar" },
  ];
  const [PremiumUserSwitch, setPremiumUserSwitch] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    name: false,
    mobile: false,
    email: false,
    password: false,
    confirmPassword: false,
    years_of_experience: false,
    speciality: false,
    availability: false,
    gender: false,
    brief_detail: false,
    fees: false,
    hospital_name: false,
    hospital_fees: false,
    hospital_address: false,
    street: false,
    country: false,
    state: false,
    city: false,
    pincode: false,
    gstNumber: false, // New field for GST number validation
    companyName: false,
    localStreet: false,
  });
  const [clinics, setClinics] = useState({
    items: [],
    has_more: false,
    searchTerm: "",
  });
  const [showPremiumUserModal, setShowPremiumUserModal] = useState(false);
  const [page, setPage] = useState(1);
  const [DuplicatePhysio, setDuplicatePhysio] = useState("");
  const [specialties, setSpecialties] = useState([]);

  const [showConfirmEmailLoginModal, setShowConfirmEmailLoginModal] = useState(false);
  const [showEmailLoginModal, setShowEmailLoginModal] = useState(false);
  
  useEffect(() => {
    if (user?.email) {
      setData((p) => ({ ...p, email: user?.email }));
    }
    if (user?.mobile_number) {
      setData((p) => ({ ...p, mobile: user?.mobile_number }));
    }
  }, []);

  const handleConfirmMobileChange = () => {
    setShowMobileConfirmModal(false);
    setShowCurrentPasswordModal(true); // Show password modal after confirmation
  };

  const handlePasswordSubmit = async (currentPassword) => {
    try {
      // Send current password along with new mobile to the API for confirmation
      const formData = new FormData();
      formData.append("password", currentPassword);
      
      const response = await post("/verify-password", formData, getAuthConfig());
      if (response.status === 200) {
        setPasswordModalSuccess("Mobile number updated successfully!");
        setPasswordModalError(""); // Clear error message
        setTimeout(() => {
          setShowCurrentPasswordModal(false);
          setUserMobileChanged(false); // Reset mobile change flag
          setLoading(false);
          navigate("/dashboard");
        }, 2000);
      }
    } catch (error) {
      setPasswordModalError(error?.response?.data?.message || "An error occurred.");
      setPasswordModalSuccess(""); // Clear success message
    }
  };
  async function getCountrie() {
    if (countriesFetched) {
      return;
    }
    try {
      setCountryLoading(true);
      const data = await get("/country", getAuthConfig());
      setCountries(data?.data?.data);
      setCountriesFetched(true);
    } catch (error) {
      console.error(error);
    } finally {
      setCountryLoading(false);
    }
  }

  async function handleCountryChange(event) {
    const countryCode = event.target.value;
    setCountry(countryCode);

    if (countryCode) {
      try {
        setStateLoading(true);
        const response = await get(
          `/state?countryCode=${countryCode}`,
          getAuthConfig()
        );
        setStates(response?.data?.data);
        setstate("");
        setData((p) => ({ ...p, state: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setStateLoading(false);
      }
    } else {
      setStates([]);
      setstate("");
    }
  }

  async function handleStateChange(event) {
    const stateCode = event.target.value;
    setstate(stateCode);

    if (country && stateCode) {
      try {
        setCityLoading(true);
        const response = await get(
          `/city?countryCode=${country}&stateCode=${stateCode}`,
          getAuthConfig()
        );
        setCities(response?.data?.data);
        setData((p) => ({ ...p, city: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCities([]);
    }
  }

  const handleProfileImgChange = (e) => {
    const file = e?.target?.files[0];
    setData((prevData) => ({
      ...prevData,
      profile: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setProfileImagePreview("");
    }
  };

  const handleClinicImgChange = (e) => {
    const file = e?.target?.files[0];
    setData((prevData) => ({
      ...prevData,
      clinic: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setClinicImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setClinicImagePreview("");
    }
  };


  const handleChange = (e) => {
    const { name, value } = e?.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
    setDuplicatePhysio("");
  };

  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector("input");
    if (inputField) {
      inputField.focus();
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef?.current?.querySelector("select");
      if (selectField) {
        selectField.classList.add("field-error");
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  async function createClinic() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!data?.name) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        name: true,
      }));

      handleFieldError(nameInputRef);
      return;
    }

    if (!data?.years_of_experience || isNaN(data?.years_of_experience)) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        years_of_experience: true,
      }));
      handleFieldError(yearsOfExperienceInputRef);
      return;
    }

    if (!data?.speciality) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        speciality: true,
      }));
      handleFieldError(specialityInputRef);
      return;
    }

    if (!data?.gender) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        gender: true,
      }));
      handleFieldError(genderInputRef);
      return;
    }

    if (!data?.type) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        associated: true,
      }));
      handleFieldError(associatedInputRef);
      return;
    }

    if (data?.type === "clinic" && !data?.clinicName) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        clinicName: true,
      }));
      handleFieldError(clinicInputRef);
      return;
    }

    if (data?.clinicName === "other" && !data?.hospital_name) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        hospital_name: true,
      }));
      handleFieldError(hospitalNameInputRef);
      return;
    }

    if (data?.clinicName === "other" && !data?.clinicPhone) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        clinicPhone: true,
      }));
      handleFieldError(hospitalclinicPhoneRef);
      return;
    }
    if (
      data?.clinicName === "other" &&
      !emailRegex.test(data?.hospital_email)
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        hospital_email: true,
      }));
      handleFieldError(hospitalhospital_emailRef);
      return;
    }

    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.street
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        street: true,
      }));
      handleFieldError(streetInputRef);
      return;
    }
    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.country
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        country: true,
      }));
      handleFieldError(countryInputRef);

      return;
    }
    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.state
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        state: true,
      }));
      handleFieldError(stateInputRef);

      return;
    }

    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.city
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        city: true,
      }));
      handleFieldError(cityInputRef);
      return;
    }

    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.pincode
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        pincode: true,
      }));
      handleFieldError(zipInputRef);
      return;
    }

    if (data?.email) {
      if (!emailRegex.test(data?.email)) {
        setInputErrors((prevErrors) => ({
          ...prevErrors,
          email: true,
        }));

        return;
      }

      if (!data?.isPasswordAdded) {
        if (!data?.password?.trim() || !data?.confirmPassword?.trim()) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: true,
          }));

          handleFieldError(passwordInputRef);
          return;
        }
        if (data?.password !== data?.confirmPassword) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            password: true,
          }));
          handleFieldError(passwordInputRef);
          return;
        }

        if (data?.password.length < 8) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            password: true,
          }));
          handleFieldError(passwordInputRef);

          return;
        }
        if (!validatePassword(data?.password)) {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            password: true,
          }));
          handleFieldError(passwordInputRef);
          return;
        }
      }
    }
    const formdata = new FormData();

    formdata.append("name", data?.hospital_name);
    formdata.append("phone", "+" + data?.clinicPhone);
    formdata.append("email", data?.hospital_email);
    formdata.append("country", data?.country);
    formdata.append("state", data?.state);
    formdata.append("city", data?.city);
    formdata.append("street", data?.street);
    formdata.append("pincode", data?.pincode);
    try {
      const response = await post("/createClinic", formdata, getAuthConfig())
        .then((data) => {
          if (data?.status === 200) {
            saveProfileDetail(data?.data?.result?._id);
          }
        })
        .catch((err) => {
          setClinicError(err?.response?.data?.err);
        });
    } catch (error) {}
  }

  function validatePassword(password) {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChar
    );
  }

  function validateGSTNumber(gstNumber) {
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/;
    return gstRegex.test(gstNumber);
  }

  async function saveProfileDetail(createdClinic) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    try {
    if (PremiumUserSwitch === true && data?.type === "clinic") {
      setShowPremiumUserModal(true);
      return;
    }
    if (!data?.name) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        name: true,
      }));

      handleFieldError(nameInputRef);
      return;
    }

    // if (!data?.years_of_experience || isNaN(data?.years_of_experience)) {
    //   setInputErrors((prevErrors) => ({
    //     ...prevErrors,
    //     years_of_experience: true,
    //   }));
    //   handleFieldError(yearsOfExperienceInputRef);
    //   return;
    // }

    if (!data?.speciality) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        speciality: true,
      }));
      handleFieldError(specialityInputRef);
      return;
    }

    if (data?.fees && !data?.currency) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        currency: true,
      }));
      handleFieldError(feesInputRef);
      return;
    }

    if (!data?.gender) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        gender: true,
      }));
      handleFieldError(genderInputRef);
      return;
    }

    if (!data?.type) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        associated: true,
      }));
      handleFieldError(associatedInputRef);
      return;
    }

    if (data?.type === "clinic" && !data?.clinicName) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        clinicName: true,
      }));
      handleFieldError(clinicInputRef);
      return;
    }

    if (data?.clinicName === "other" && !data?.hospital_name) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        hospital_name: true,
      }));
      handleFieldError(hospitalNameInputRef);
      return;
    }

    if (data?.clinicName === "other" && !data?.clinicPhone) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        clinicPhone: true,
      }));
      handleFieldError(hospitalclinicPhoneRef);
      return;
    }
    if (
      data?.clinicName === "other" &&
      !emailRegex.test(data?.hospital_email)
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        hospital_email: true,
      }));
      handleFieldError(hospitalhospital_emailRef);
      return;
    }

    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.street
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        street: true,
      }));
      handleFieldError(streetInputRef);
      return;
    }
    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.country
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        country: true,
      }));
      handleFieldError(countryInputRef);

      return;
    }
    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.state
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        state: true,
      }));
      handleFieldError(stateInputRef);

      return;
    }

    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.city
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        city: true,
      }));
      handleFieldError(cityInputRef);
      return;
    }

    if (
      (data?.clinicName === "other" || data?.type === "Individual") &&
      !data?.pincode
    ) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        pincode: true,
      }));
      handleFieldError(zipInputRef);
      return;
    }

    if (data?.email) {
      if (!emailRegex.test(data?.email)) {
        setInputErrors((prevErrors) => ({
          ...prevErrors,
          email: true,
        }));

        return;
      }

      // if (!data?.isPasswordAdded) {
      //   if (!data?.password?.trim() || !data?.confirmPassword?.trim()) {
      //     setInputErrors((prevErrors) => ({
      //       ...prevErrors,
      //       confirmPassword: true,
      //     }));

      //     handleFieldError(passwordInputRef);
      //     return;
      //   }
      //   if (data?.password !== data?.confirmPassword) {
      //     setInputErrors((prevErrors) => ({
      //       ...prevErrors,
      //       confirmPassword: true,
      //     }));
      //     handleFieldError(confirmPasswordInputRef);
      //     return;
      //   }

      //   if (data?.password.length < 8) {
      //     setInputErrors((prevErrors) => ({
      //       ...prevErrors,
      //       password: true,
      //     }));
      //     handleFieldError(passwordInputRef);

      //     return;
      //   }
      //   if (!validatePassword(data?.password)) {
      //     setInputErrors((prevErrors) => ({
      //       ...prevErrors,
      //       password: true,
      //     }));
      //     handleFieldError(passwordInputRef);
      //     return;
      //   }
      // }
    }

    if (data?.gstNumber && !validateGSTNumber(data?.gstNumber)) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        gstNumber: true,
      }));
      handleFieldError(gstNumberRef);
      return;
    }

    const formdata = new FormData();

    formdata?.append("name", data?.name);
    if (data?.profile) {
      formdata?.append("profile_image", data?.profile);
    } if (data?.clinic) {
      formdata?.append("clinic_image", data?.clinic);
    }
    if (data?.mobile) {
      if (!data?.mobile?.startsWith("+")) {
        formdata?.append("mobile", "+" + data?.mobile);
      } else {
        formdata?.append("mobile", data?.mobile);
      }
    }
    if (data?.email) {
      formdata?.append("email", data?.email);
    }
    formdata?.append("years_of_experience", data?.years_of_experience || "");
    formdata?.append("speciality", data?.speciality || "");
    formdata?.append("avaiability", data?.availability || "");
    formdata?.append("fees", data?.fees || "");
    formdata?.append("currency", data?.currency || "");
    formdata?.append("hospital_name", data?.hospital_name || "");
    formdata?.append("hospital_fees", data?.hospital_fees || "");
    formdata?.append("hospital_address", data?.hospital_address || "");
    formdata?.append("street", data?.street || "");
    formdata?.append("localStreet", data?.localStreet || ""); // Add localStreet to formdata
    formdata?.append("city", data?.city || "");
    formdata?.append("state", data?.state || "");
    formdata?.append("country", data?.country || "");
    formdata?.append("pincode", data?.pincode);
    if(data?.building)
    {
      formdata?.append("building", data?.building);
    }
    if(data?.additionalDetails){
      formdata?.append("additionalDetails", data?.additionalDetails);
    }
    formdata?.append("brief_detail", data?.brief_detail || "");
    formdata?.append("gender", data?.gender);
    if(data?.licence_number)
    {
      formdata?.append("licence_number", data?.licence_number);
    }
    formdata?.append("gstNumber", data?.gstNumber || ""); // Add gstNumber to formdata
    formdata?.append("companyName", data?.companyName || ""); // Add companyName to formdata

    if (selected?.lat) {
      formdata?.append("latitude", selected?.lat);
    }
    if (selected?.lng) {
      formdata?.append("longitude", selected?.lng);
    }

    if (!createdClinic) {
      if (data?.type === "clinic") {
        if (data?.clinicName) {
          formdata?.append("associatedEntity", data?.clinicName);
        }
      } else {
        formdata?.append("associatedEntity", "Individual");
      }
    } else {
      formdata?.append("associatedEntity", createdClinic);
    }

    if (data?.password && data?.password.trim() != "") {
      formdata?.append("password", data?.password);
    }

    
      setLoading(true);
      const response = await post(
        "/physiotherapist",
        formdata,
        getAuthConfigImage()
      );
      if (response?.status === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            email: response?.data?.msg?.email,
            mobile_number: response?.data?.msg?.mobile,
            token: response?.data?.msg?.token,
            name: response?.data?.msg?.name,
            profile_image: response?.data?.msg?.profile_image,
            _id: response?.data?.msg?._id,
            profileCompleted: response?.data?.msg?.profileCompleted,
            gender: response?.data?.msg?.gender,
          })
        );
        if(response?.data?.msg?.email && !response?.data?.msg?.isPasswordAdded)
        {
          setShowConfirmEmailLoginModal(true); // Show confirmation modal if email is not provided
        }
        else if  (userMobileChanged) {
          setShowMobileConfirmModal(true); // Trigger the mobile confirmation modal
        }
        else{
 setTimeout(() => {
          setLoading(false);
          navigate("/dashboard");
        }, 2000);
        }
       
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.err || error.message);
      console.log("Errror",error)
    }
  }
  const [showOtpModal, setShowOtpModal] = useState(false); // OTP Modal state
  const [otpSent, setOtpSent] = useState(false);
  console.log(data,"Data From Profiel")
  const sendOtp = async (email) => {
    try {
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("type", "physiotherapist");
      formdata.append("device", "Web");

      const response = await put("/sendOTPEmail", formdata);
      if (response?.status === 200) {
        setOtpSent(true);
        toast.success("OTP sent successfully!");
        return true;
      }
    } catch (error) {
      toast.error("Failed to send OTP. Please try again.");
      return false;
    }
  };

  // Function to verify OTP
  const verifyOtp = async (otp, email) => {
    try {
      const formdata = new FormData();
      formdata.append("type", "physiotherapist");
      formdata.append("email", email);
      formdata.append("OTP", otp);
      formdata.append("device", "Web");

      const response = await post("/verifyOTP", formdata);
      if (response?.status === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            email: response?.data?.physiotherapist?.email,
            mobile_number: response?.data?.physiotherapist?.mobile,
            token: response?.data?.physiotherapist?.token,
            profile_image: response?.data?.physiotherapist?.profile_image,
            _id: response?.data?.physiotherapist?._id,
            profileCompleted: response?.data?.physiotherapist?.profileCompleted,
            gender: response?.data?.physiotherapist?.genders,
            name: response?.data?.physiotherapist?.name,
          })
        );
        toast.success("OTP verified successfully!");
        return true;
      }
    } catch (error) {
      toast.error("Invalid OTP. Please try again.");
      return false;
    }
  };

  const handleConfirmEmailLogin = async () => {
    try {
      setOTPLoading(true); // Start loading before making the API call
      const formdata = new FormData();
      formdata.append("email", data?.email);
      formdata.append("type", "physiotherapist");
      formdata.append("device", "Web");

      const response = await put("/sendOTPEmail", formdata);
      if (response?.status === 200) {
        setConfirmEmailSuccess("OTP sent successfully!");
        setConfirmEmailError(""); // Clear any error message
        setTimeout(() => {
          setShowConfirmEmailLoginModal(false); // Close confirm modal
          setShowOtpModal(true); // Show OTP modal after success
        }, 1000);
      }
    } catch (error) {
      setConfirmEmailError("Failed to send OTP. Please try again.");
      setConfirmEmailSuccess(""); // Clear success message
    }
    finally {
      setOTPLoading(false); // Stop loading after API call
    }
  };

  const handleOtpVerification = async (otp) => {
    try {
      const formdata = new FormData();
      formdata.append("type", "physiotherapist");
      formdata.append("email", data?.email);
      formdata.append("OTP", otp);
      formdata.append("device", "Web");

      const response = await post("/verifyOTP", formdata);
      if (response?.status === 200) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            email: response?.data?.physiotherapist?.email,
            mobile_number: response?.data?.physiotherapist?.mobile,
            token: response?.data?.physiotherapist?.token,
            profile_image: response?.data?.physiotherapist?.profile_image,
            _id: response?.data?.physiotherapist?._id,
            profileCompleted: response?.data?.physiotherapist?.profileCompleted,
            gender: response?.data?.physiotherapist?.genders,
            name: response?.data?.physiotherapist?.name,
          })
        );
        setOtpSuccess("OTP verified successfully!");
        setOtpError("");
        setTimeout(() => {
          setShowOtpModal(false);
          setShowEmailLoginModal(true); // Open the email login modal after success
        }, 1000);
      }
    } catch (error) {
      setOtpError("Invalid OTP. Please try again.");
      setOtpSuccess("");
    }
  };

  async function getHomeData() {
    try {
      const data = await get("/physiotherapist", getAuthConfig());
      if (data?.status === 200) {
        let modifiedData = { ...data?.data?.doc };

        modifiedData.type =
          typeof modifiedData.associatedEntity === "object"
            ? "clinic"
            : "Individual";
        modifiedData.availability = data?.data?.doc?.avaiability;
        modifiedData.clinicName =
          typeof modifiedData.associatedEntity === "object" &&
          modifiedData?.associatedEntity?._id;

        if (
          modifiedData?.isPremiumUser &&
          modifiedData?.subscriptionId &&
          modifiedData.associatedEntity === "Individual"
        ) {
          setPremiumUserSwitch(true);
        }
        if (typeof modifiedData.associatedEntity === "object") {
          setSelectedClinic(modifiedData.associatedEntity);
        } else {
          modifiedData.country = data?.data?.doc?.address?.country;
          modifiedData.state = data?.data?.doc?.address?.state;
          modifiedData.city = data?.data?.doc?.address?.city;
          modifiedData.street = data?.data?.doc?.address?.street;
          modifiedData.localStreet = data?.data?.doc?.address?.localStreet;
          modifiedData.pincode = data?.data?.doc?.address?.pincode;
          modifiedData.building = data?.data?.doc?.address?.building;
          modifiedData.additionalDetails = data?.data?.doc?.address?.additionalDetails
        }

        setData(modifiedData);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }

  UseEffectOnce(() => {
    getHomeData();

    if (!Auth.getCurrentUser()) {
      navigate("/login");
    } else {
      const fetchSpecialties = async () => {
        try {
          const response = await get("/specialties", getAuthConfig());
          if (response?.status === 200) {
            setSpecialties(response?.data?.specialties);
          }
        } catch (error) {
          console.error("Error fetching specialties:", error);
        }
      };

      fetchSpecialties();
    }
  });

  const checkForExistingPatient = async (isEmail) => {
    try {
      const formdata = new FormData();
      if (!data?.mobile?.startsWith("+")) {
        formdata.append("phone", "+" + data?.mobile);
      } else {
        formdata.append("phone", data?.mobile);
      }
      if (data?.email) {
        formdata.append("email", data?.email);
      }
      formdata.append("type", "physiotherapist");
      const response = await post(
        "/checkForPatient",
        formdata,
        getAuthConfig()
      );

      if (response?.status === 200) {
        if (data?.type === "clinic" && data?.clinicName === "other") {
          createClinic();
        } else {
          saveProfileDetail();
        }
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        setDuplicatePhysio(error?.response?.data?.msg);
        if (error?.response?.data?.msg === "Email already exists") {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            email: true,
          }));
          handleFieldError(emailInputRef);
          return;
        }
        if (error?.response?.data?.msg === "Phone already exists") {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            mobile: true,
          }));
          handleFieldError(mobileInputRef);
          return;
        }
      }
      console.error("Error checking for existing patient:", error);
    }
  };
  const handleEmailLoginSubmit = async () => {
    try {
      // API call to save the profile detail
      await saveProfileDetail(); 
      setEmailLoginSuccess("Profile saved successfully!");
      setEmailLoginError(""); // Clear error message
      setTimeout(() => {
        setShowEmailLoginModal(false);
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      setEmailLoginError("Failed to save profile. Please try again.");
      setEmailLoginSuccess(""); // Clear success message
    }
  };

  const getCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };

  const getAddressFromCoordinates = async (latitude, longitude) => {
    const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;

    try {
      const response = await axios.get(nominatimUrl, {
        headers: {
          "User-Agent": "YourAppName",
        },
      });

      const addressData = response.data;
      if (addressData && addressData.display_name) {
        const { city, state_district, state, country, postcode } =
          addressData.address;

        setData((p) => ({
          ...p,
          city: city || state_district || "",
          state: state || "",
          country: country || "",
          pincode: postcode || "",
          street: addressData.display_name,
        }));
        setSelected({
          lat: latitude,
          lng: longitude,
          address: addressData?.display_name,
        });
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  useEffect(() => {
    fetchClinics();
  }, [clinics?.searchTerm, page]);
  const fetchClinics = async () => {
    try {
      const response = await get(
        `/getAllClinic?pageNumber=${page}&searchTerm=${clinics?.searchTerm}`,
        getAuthConfig()
      );
      if (response?.status === 200) {
        const otherClinic = { _id: "other", name: "Add more" };
  
        setClinics((p) => {
          let combinedClinics = [];
  
          if (page === 1) {
            // For page 1, reset the clinic list
            combinedClinics = [...(response?.data?.clinics || [])];
          } else {
            // For subsequent pages, merge with existing clinics
            combinedClinics = [
              ...(p.items || []).filter((clinic) => clinic._id !== otherClinic._id),
              ...(response?.data?.clinics || []).filter(
                (clinic) => clinic._id !== otherClinic._id
              ),
            ];
          }
  
          combinedClinics.push(otherClinic);
  
          return {
            ...p,
            items: combinedClinics,
            has_more: response?.data?.has_more,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching clinics:", error);
    }
  };
  

  const deleteProfileImage = async () => {
    try {
      const response = await deletes("/profileImage");

      if (response.status === 200) {
        setData({ ...data, profile_image: "" });
        setProfileImagePreview("");
      } else {
        console.error("Failed to delete profile image");
      }
    } catch (error) {
      console.error("Error deleting profile image:", error);
    }
  };

  const deleteClinicImage = async () => {
    try {
      const response = await deletes("/profileImage?type=clinic_image");

      if (response.status === 200) {
        setData({ ...data, clinic_image: "" });
        setClinicImagePreview("");
      } else {
        console.error("Failed to delete profile image");
      }
    } catch (error) {
      console.error("Error deleting profile image:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="profile-main ">
        <div className="header-part">
          <div className="left-part">
            <button
              className="back btn btn-primary"
              onClick={(e) => {
                e.preventDefault();

                navigate(-1);
              }}
            >
              <img src={BackIcon} alt="btn-icon" />
            </button>
            <h2>Your Profile</h2>
          </div>
          <div className="right-part"></div>
        </div>

        <div className="form-data full-width-data mt-5">
          <Row className="g-4">
            <Col lg="6">
              <div className="d-flex justify-content-start gap-3">
              <div className="filed" ref={profileInputRef}>
                <label htmlFor="profile-img" className="upload">
                  <div className="profile-pic">
                    {profileImagePreview ? (
                      <>
                        <img
                          src={profileImagePreview}
                          alt="Profile Preview"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "55%",
                          }}
                        />
                        <button
                          onClick={() => {
                            setData({ ...data, profile_image: "" });
                            setProfileImagePreview("");
                          }}
                          className="delete-profile-image-button"
                        >
                          <i
                            className="fa-solid fa-xmark "
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </>
                    ) : data.profile_image ? (
                      <>
                        <img
                          src={data.profile_image}
                          alt="Exercise Preview"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "55%",
                          }}
                        />
                        <button
                          onClick={deleteProfileImage}
                          className="delete-profile-image-button"
                        >
                          <i
                            className="fa-solid fa-xmark "
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </>
                    ) : (
                      <img
                        src={profileUpload}
                        alt="Exercise Image"
                        className="img-fluid"
                        style={{
                          height: "90px",
                          width: "90px",
                          borderRadius: "0%",
                        }}
                      />
                    )}
                    <p className="mt-2">Profile Picture</p>
                  </div>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="profile-img"
                  name="profile"
                  onChange={handleProfileImgChange}
                />
              </div>

              <div className="filed">
                <label htmlFor="clinic-img" className="upload">
                  <div className="profile-pic">
                    {clinicImagePreview ? (
                      <>
                        <img
                          src={clinicImagePreview}
                          alt="Profile Preview"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "55%",
                          }}
                        />
                        <button
                          onClick={() => {
                            setData({ ...data, clinic_image: "" });
                            setClinicImagePreview("");
                          }}
                          className="delete-profile-image-button"
                        >
                          <i
                            className="fa-solid fa-xmark "
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </>
                    ) : data.clinic_image ? (
                      <>
                        <img
                          src={data.clinic_image}
                          alt="Exercise Preview"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "55%",
                          }}
                        />
                        <button
                          onClick={deleteClinicImage}
                          className="delete-profile-image-button"
                        >
                          <i
                            className="fa-solid fa-xmark "
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </>
                    ) : (
                      <img
                        src={profileUpload}
                        alt="Exercise Image"
                        className="img-fluid"
                        style={{
                          height: "90px",
                          width: "90px",
                          borderRadius: "0%",
                        }}
                      />
                    )}
                    <p className="mt-2">Clinic Logo</p>
                  </div>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="clinic-img"
                  name="clinic"
                  onChange={handleClinicImgChange}
                />
              </div>
              </div>
              <div className={`filed `} ref={nameInputRef}>
                <label>
                  Name <span className="text-danger">* </span>
                </label>
                <input
                  type="text"
                  className={`input-field form-control ${
                    inputErrors?.name ? "field-error" : ""
                  }`}
                  name="name"
                  onChange={handleChange}
                  value={data?.name || ""}
                />
                {inputErrors?.name && (
                  <FieldValidationError message="Please enter your full name" />
                )}
              </div>

              <div className={`filed`} ref={mobileInputRef}>
                <label>
                  Contact Number{" "}
                  {user?.email ? "" : <span className="text-danger">* </span>}{" "}
                </label>
                <PhoneInput
                  country={"in"}
                  name="mobile"
                  value={data?.mobile}
                  onChange={(phone) => {
                    setData((prevData) => ({ ...prevData, mobile: phone }));
                    setUserMobileChanged(true); // Reset mobile change flag
                  }}
                  className={` ${inputErrors?.mobile ? "field-error" : ""}`}
                  disabled={user?.mobile_number}
                />
                {!DuplicatePhysio && inputErrors?.mobile && (
                  <FieldValidationError message="Please enter a valid contact number" />
                )}
              </div>

              <div className={`filed `} ref={emailInputRef}>
                <label>
                  Email id{" "}
                  {user?.mobile_number ? (
                    ""
                  ) : (
                    <span className="text-danger">* </span>
                  )}
                </label>
                <input
                  type="email"
                  className={`input-field form-control ${
                    inputErrors?.email ? "field-error" : ""
                  }`}
                  name="email"
                  onChange={handleChange}
                  value={data?.email || ""}
                  disabled={user?.email}
                />
                {!DuplicatePhysio && inputErrors?.email && (
                  <FieldValidationError message="Please enter a valid email address" />
                )}
              </div>

              {DuplicatePhysio && (
                <FieldValidationError message={DuplicatePhysio} />
              )}

              {/* {data?.email && !data?.isPasswordAdded && (
                <>
                  <div className="filed" ref={passwordInputRef}>
                    <label>
                      Password <span className="text-danger">* </span>
                    </label>
                    <input
                      type="password"
                      className={`input-field form-control ${
                        inputErrors?.password ? "field-error" : ""
                      }`}
                      name="password"
                      value={data?.password}
                      onChange={handleChange}
                    />
                    {inputErrors?.password && (
                      <FieldValidationError message="Password must be at least 8 characters long, contain uppercase and lowercase letters, a number, and a special character." />
                    )}
                  </div>
                  <div className="filed">
                    <label>
                      Confirm Password <span className="text-danger">* </span>
                    </label>
                    <input
                      type="password"
                      className={`input-field form-control ${
                        inputErrors?.confirmPassword ? "field-error" : ""
                      }`}
                      name="confirmPassword"
                      value={data?.confirmPassword}
                      onChange={handleChange}
                    />
                    {inputErrors?.confirmPassword && (
                      <FieldValidationError message="Password not matching" />
                    )}
                  </div>
                </>
              )} */}

             
              <div className={`filed `} ref={specialityInputRef}>
                <label>
                  Speciality <span className="text-danger">*</span>
                </label>
                <select
                  className={`form-control form-select  ${
                    inputErrors?.speciality ? "field-error" : ""
                  }`}
                  name="speciality"
                  onChange={handleChange}
                  value={data?.speciality || ""}
                >
                  <option value="" disabled>
                    Select your speciality
                  </option>
                  {specialties?.length > 0 &&
                    specialties.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
                {inputErrors?.speciality && (
                  <FieldValidationError message="Please select your speciality" />
                )}
              </div>
              <div className="filed  " ref={genderInputRef}>
                <label>
                  Gender <span className="text-danger">* </span>
                </label>
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  onChange={handleChange}
                  value={data?.gender || ""}
                  className={`form-control form-select ${
                    inputErrors?.gender ? "field-error" : ""
                  }`}
                >
                  <option value="">Please select Gender</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="non-binary">Non-Binary</option>
                  <option value="other">Other</option>
                  <option value="Prefer not to answer">
                    Perfer not to Answer
                  </option>
                </Form.Select>
                {inputErrors?.gender && (
                  <FieldValidationError message="Please select gender" />
                )}
              </div>
              <div className="filed">
                  
                      <div className="filed" ref={streetInputRef}>
                        <label>
                          Address <span className="text-danger">* </span>
                        </label>
                        <PlacesAutoComplete
                          data={data}
                          setFormData={setData}
                          selected={selected}
                          setSelected={setSelected}
                          isLoaded={isLoaded}
                          setInputErrors={setInputErrors}
                        />
                        {inputErrors?.street && (
                          <FieldValidationError message="Please enter your Full Address" />
                        )}
                      </div>
                      <div className="filed" ref={localStreetInputRef}>
                        <label>Builiding / Floor / House / Flat </label>
                        <input
                          type="text"
                          className="input-field form-control"
                          name="building"
                          placeholder="Builiding / Floor / House / Flat"
                          value={data?.building || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="filed" ref={localStreetInputRef}>
                        <label>Additional Address Details</label>
                        <input
                          type="text"
                          className="input-field form-control"
                          name="additionalDetails"
                          placeholder="Additional Address Details"
                          value={data?.additionalDetails || ""}
                          onChange={handleChange}
                        />
                      </div>
                    {/* <Col lg="6" className="">
                      <div className="filed" ref={companyNameRef}>
                        <label>Company Name</label>
                        <input
                          type="text"
                          className="input-field form-control"
                          name="companyName"
                          value={data?.companyName || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={gstNumberRef}>
                        <label>GST Number</label>
                        <input
                          type="text"
                          className={`input-field form-control ${
                            inputErrors?.gstNumber ? "field-error" : ""
                          }`}
                          name="gstNumber"
                          value={data?.gstNumber || ""}
                          onChange={handleChange}
                        />
                        {inputErrors?.gstNumber && (
                          <FieldValidationError message="Please enter valid GST number" />
                        )}
                      </div>
                    </Col> */}
                </div>
            </Col>
            <Col lg="6">
            <h5 >Additional Details (Optional)</h5>

            

              <div className="filed mt-3" ref={briefDetailInputRef}>
                <label>Licence Number </label>
                <input
                  type="textarea"
                  className={`input-field form-control ${
                    inputErrors?.licence_number ? "field-error" : ""
                  }`}
                  name="licence_number"
                  onChange={handleChange}
                  value={data?.licence_number || ""}
                />
                {inputErrors?.licence_number && (
                  <FieldValidationError message="Please enter licence_number" />
                )}
              </div>
              <div className="filed " ref={briefDetailInputRef}>
                <label>Brief detail </label>
                <input
                  type="textarea"
                  className={`input-field form-control ${
                    inputErrors?.brief_detail ? "field-error" : ""
                  }`}
                  name="brief_detail"
                  onChange={handleChange}
                  value={data?.brief_detail || ""}
                />
                {inputErrors?.brief_detail && (
                  <FieldValidationError message="Please enter some details about you" />
                )}
              </div>
              <div className="filed" ref={yearsOfExperienceInputRef}>
                <label>
                  Years of Exeperince 
                </label>
                <input
                  type="text"
                  className={`input-field form-control ${
                    inputErrors?.years_of_experience ? "field-error" : ""
                  }`}
                  name="years_of_experience"
                  onChange={(e) => {
                    const value = e.target.value;

                    if (value === "" || /^[0-9]*$/.test(value)) {
                      let newAge = value === "" ? "" : parseInt(value, 10);

                      if (newAge === "" || (newAge >= 0 && newAge <= 100)) {
                        setData((p) => ({
                          ...p,
                          years_of_experience: newAge,
                        }));
                        setInputErrors((p) => ({
                          ...p,
                          years_of_experience: false,
                        }));
                      } else if (newAge > 100) {
                        setInputErrors((p) => ({
                          ...p,
                          years_of_experience: false,
                        }));
                      }
                    } else {
                      setInputErrors((p) => ({
                        ...p,
                        years_of_experience: true,
                      }));
                    }
                  }}
                  min="0"
                  value={data?.years_of_experience || ""}
                />
                {inputErrors?.years_of_experience && (
                  <FieldValidationError message="Please enter your experience" />
                )}
              </div>
              <div className="filed" ref={feesInputRef}>
                <label>Fees </label>
                <div className="d-flex">
                  <select
                    name="currency"
                    value={data?.currency || ""}
                    className={`w-25 input-field form-control ${
                      inputErrors?.currency ? "field-error" : ""
                    }`}
                    onChange={handleChange}
                  >
                    <option value="">Select currency</option>
                    <option value="INR">₹ INR</option>
                    <option value="USD">$ USD</option>
                    <option value="EUR">€ EURO</option>
                    <option value="CAD">$ CAD</option>
                  </select>

                  <input
                    type="text"
                    className={` w-75 input-field form-control ${
                      inputErrors?.fees ? "field-error" : ""
                    }`}
                    name="fees"
                    min="0"
                    onChange={(e) => {
                      e.preventDefault();
                      const value = e.target.value;
                      if (value === "" || /^[0-9]*$/.test(value)) {
                        let newAge = value === "" ? "" : parseInt(value, 10);

                        if (newAge === "" || newAge >= 0) {
                          setData((p) => ({
                            ...p,
                            fees: newAge,
                          }));
                          setInputErrors((p) => ({ ...p, fees: false }));
                        }
                      } else {
                        setInputErrors((p) => ({ ...p, fees: true }));
                      }
                    }}
                    value={data?.fees || ""}
                  />
                </div>
              </div>
              {inputErrors?.currency && (
                <FieldValidationError message="Please select currency" />
              )}

              {inputErrors?.fees && (
                <FieldValidationError message="Please enter fees" />
              )}

              {/* <div className="filed" ref={associatedInputRef}>
                <label>
                  Associated with <span className="text-danger">*</span>
                </label>
                <div className="radio-buttons d-flex">
                  <label className="w-25 align-items-center d-flex">
                    <input
                      type="radio"
                      name="type"
                      value="Individual"
                      checked={data?.type === "Individual"}
                      onChange={(e) => {
                        setInputErrors((p) => ({ ...p, associated: false }));
                        handleChange(e);
                      }}
                    />
                    Individual
                  </label>
                  <label className="w-25 align-items-center d-flex">
                    <input
                      type="radio"
                      name="type"
                      value="clinic"
                      checked={data?.type === "clinic"}
                      onChange={(e) => {
                        setInputErrors((p) => ({ ...p, associated: false }));
                        handleChange(e);
                      }}
                    />
                    Clinic
                  </label>
                </div>
                {inputErrors?.associated === true ? (
                  <FieldValidationError message="Please select associated entity" />
                ) : (
                  ""
                )}
              </div>

              {data?.type === "clinic" && (
                <>
                  <div className="filed" ref={clinicInputRef}>
                    <div className="clinic-dropdown">
                      <label>
                        Select Clinic <span className="text-danger">*</span>
                      </label>

                      <Select
                        placeholder="Select Clinics"
                        id="clinic_id"
                        name="clinic_id"
                        className={`form-select p-0 py-2 ${
                          inputErrors?.clinicName ? "field-error" : ""
                        }`}
                        styles={{ padding: "5px" }}
                        value={
                          selectedClinic
                            ? {
                                value: selectedClinic._id,
                                label: selectedClinic.name,
                              }
                            : null
                        }
                        options={
                          clinics?.items &&
                          clinics?.items?.map(function (productArray) {
                            return {
                              value: productArray?._id,
                              label: productArray?.name,
                            };
                          })
                        }
                        onKeyDown={(e) => {
                          setClinics((p) => ({
                            ...p,
                            searchTerm: e.target.value,
                          }));
                        }}
                        onChange={(e) => {
                          const selectedClinic = clinics?.items.find(
                            (clinic) => clinic._id === e.value
                          );
                          if (e.value === "other") {
                            setSelectedClinic(null);
                            setData((p) => ({
                              ...p,
                              clinicName: e.value,
                              hospital_name: "",
                              clinicPhone: "",
                              hospital_email: "",
                              street: "",
                              country: "",
                              state: "",
                              city: "",
                              pincode: "",
                            }));
                          } else {
                            setSelectedClinic(selectedClinic);
                            setData((p) => ({
                              ...p,
                              clinicName: e.value,
                              hospital_name: selectedClinic?.name || "",
                              clinicPhone: selectedClinic?.phone || "",
                              hospital_email: selectedClinic?.email || "",
                              street: selectedClinic?.address?.street || "",
                              country: selectedClinic?.address?.country || "",
                              state: selectedClinic?.address?.state || "",
                              city: selectedClinic?.address?.city || "",
                              pincode: selectedClinic?.address?.pincode || "",
                            }));
                          }
                          if (inputErrors?.clinicName === true) {
                            setInputErrors((p) => ({
                              ...p,
                              clinicName: false,
                            }));
                          }
                        }}
                        onBlur={(e) => {
                          e.preventDefault();
                        }}
                        onMenuScrollToBottom={(e) => {
                          if (clinics?.has_more === true) {
                            let p = page + 1;
                            setPage(p);
                          }
                        }}
                      ></Select>

                      {inputErrors?.clinicName && (
                        <FieldValidationError message="Please select a clinic" />
                      )}
                    </div>
                  </div>

                  {data?.clinicName === "other" ? (
                    <>
                      <div className="filed" ref={hospitalNameInputRef}>
                        <label>
                          Hospital name <span className="text-danger">* </span>
                        </label>
                        <input
                          type="text"
                          className={`input-field form-control ${
                            inputErrors?.hospital_name ? "field-error" : ""
                          }`}
                          name="hospital_name"
                          onChange={handleChange}
                          value={data?.hospital_name || ""}
                        />
                        {inputErrors?.hospital_name && (
                          <FieldValidationError message="Please enter hospital name" />
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="filed" ref={hospitalclinicPhoneRef}>
                    <label>
                      Hospital Contact Number{" "}
                      <span className="text-danger">* </span>
                    </label>
                    <PhoneInput
                      country={"in"}
                      name="mobileClinic"
                      className={`${
                        inputErrors?.clinicPhone ? "field-error" : ""
                      }`}
                      disabled={data?.clinicName === "other" ? false : true}
                      value={selectedClinic?.phone || data?.clinicPhone}
                      onChange={(phone) => {
                        setData((prevData) => ({
                          ...prevData,
                          clinicPhone: phone,
                        }));
                        setInputErrors((p) => ({ ...p, clinicPhone: false }));
                      }}
                    />
                    {inputErrors?.clinicPhone && (
                      <FieldValidationError message="Please enter valid contact number for the hospital " />
                    )}
                  </div>
                  <div className="filed" ref={hospitalhospital_emailRef}>
                    <label>
                      Hospital Email <span className="text-danger">* </span>
                    </label>
                    <input
                      type="text"
                      className={`input-field form-control ${
                        inputErrors?.hospital_email ? "field-error" : ""
                      }`}
                      name="hospital_email"
                      disabled={data?.clinicName === "other" ? false : true}
                      value={selectedClinic?.email || data?.hospital_email}
                      onChange={handleChange}
                    />

                    {inputErrors?.hospital_email && (
                      <FieldValidationError message="Please enter valid contact email for the hospital " />
                    )}
                  </div>
                  {clinicError && (
                    <FieldValidationError message={clinicError} />
                  )}

                  <Row>
                    <Col lg="6" className="">
                      <div className="filed" ref={streetInputRef}>
                        <label>
                          Address <span className="text-danger">* </span>
                        </label>

                        <input
                          type="text"
                          className={`input-field form-control`}
                          name="street"
                          disabled={data?.clinicName === "other" ? false : true}
                          value={
                            selectedClinic?.address?.street || data?.street
                          }
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={countryInputRef}>
                        <label>
                          Country <span className="text-danger">* </span>
                        </label>

                        <select
                          className={`form-control form-select   ${
                            inputErrors?.country ? "field-error" : ""
                          }`}
                          value={country}
                          disabled={data?.clinicName === "other" ? false : true}
                          onChange={(e) => {
                            handleCountryChange(e);
                            const selectedCountry = countries.find(
                              (c) => c.isoCode === e.target.value
                            );
                            if (selectedCountry) {
                              setData((prevData) => ({
                                ...prevData,
                                country: selectedCountry.name,
                              }));
                            }
                            setInputErrors((p) => ({ ...p, country: false }));
                          }}
                          onClick={(e) => {
                            getCountrie();
                          }}
                        >
                          <option value="">
                            {selectedClinic?.address?.country
                              ? selectedClinic?.address?.country
                              : "Select Country"}
                          </option>
                          {countryLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            countries.map((country) => (
                              <option
                                key={country?.code}
                                value={country?.isoCode}
                              >
                                {country?.name}
                              </option>
                            ))
                          )}
                        </select>
                        {inputErrors?.country && (
                          <FieldValidationError message="Please select country" />
                        )}
                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={stateInputRef}>
                        <label>
                          State or Province{" "}
                          <span className="text-danger">* </span>
                        </label>

                        <select
                          className={`form-select ${
                            inputErrors?.state ? "field-error" : ""
                          }`}
                          disabled={data?.clinicName === "other" ? false : true}
                          value={state}
                          onChange={(e) => {
                            handleStateChange(e);
                            const selectedStates = states.find(
                              (c) => c.isoCode === e.target.value
                            );
                            if (selectedStates) {
                              setData((prevData) => ({
                                ...prevData,
                                state: selectedStates.name,
                              }));
                            }

                            setInputErrors((p) => ({ ...p, state: false }));
                          }}
                        >
                          <option value="">
                            {selectedClinic?.address?.state
                              ? selectedClinic?.address?.state
                              : "Select State"}
                          </option>
                          {stateLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            states.map((state) => (
                              <option key={state?.code} value={state?.isoCode}>
                                {state?.name}
                              </option>
                            ))
                          )}
                        </select>

                        {inputErrors?.state && (
                          <FieldValidationError message="Please select state" />
                        )}
                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={cityInputRef}>
                        <label>
                          City <span className="text-danger">* </span>
                        </label>

                        <select
                          className={`form-control form-select ${
                            inputErrors?.city ? "field-error" : ""
                          }`}
                          disabled={data?.clinicName === "other" ? false : true}
                          value={data?.hospital_city}
                          onChange={(e) => {
                            setData((p) => ({ ...p, city: e?.target?.value }));
                            setInputErrors((p) => ({ ...p, city: false }));
                          }}
                        >
                          <option value="">
                            {selectedClinic?.address?.city
                              ? selectedClinic?.address?.city
                              : "Select City"}
                          </option>
                          {cityLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            cities.map((city) => (
                              <option key={city?.code} value={city?.code}>
                                {city?.name}
                              </option>
                            ))
                          )}
                        </select>
                        {inputErrors?.city && (
                          <FieldValidationError message="Please select city" />
                        )}
                      </div>
                    </Col>
                    <Col lg="6" className="">
                      <div className="filed" ref={zipInputRef}>
                        <label>
                          Postal code or ZIP code{" "}
                          <span className="text-danger">* </span>
                        </label>

                        <input
                          type="number"
                          className={`input-field form-control  ${
                            inputErrors?.pincode ? "field-error" : ""
                          }`}
                          disabled={data?.clinicName === "other" ? false : true}
                          name="pincode"
                          onChange={handleChange}
                          value={
                            selectedClinic?.address?.pincode || data?.pincode
                          }
                        />
                        {inputErrors?.pincode && (
                          <FieldValidationError message="Please enter valid pincode" />
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )} */}

             
            </Col>
          </Row>
          <div className="buttonPart mt-5 d-flex justify-content-center mb-4">
            <Button
              className="theme-button"
              onClick={checkForExistingPatient}
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </div>
        </div>

        <PremiumUserSwitchModal
          show={showPremiumUserModal}
          onHide={setShowPremiumUserModal}
        />

<ConfirmEmailLoginModal
  loading={otploading}  // Pass the loading state to the modal

        show={showConfirmEmailLoginModal}
        onHide={() => setShowConfirmEmailLoginModal(false)}
        onConfirm={handleConfirmEmailLogin}
        errorMessage={confirmEmailError}
        successMessage={confirmEmailSuccess}
      />

      {/* OTP Modal */}
      <OtpModal
        show={showOtpModal}
        onHide={() => setShowOtpModal(false)}
        onVerify={handleOtpVerification}
        email={data?.email}
        errorMessage={otpError}
        successMessage={otpSuccess}
      />

      {/* Email Login Modal */}
      <EmailLoginModal
        show={showEmailLoginModal}
        data={data}
        setData={setData}
        onHide={() => setShowEmailLoginModal(false)}
        onSubmit={handleEmailLoginSubmit}
        errorMessage={emailLoginError}
        successMessage={emailLoginSuccess}
      />

<MobileConfirmModal
          show={showMobileConfirmModal}
          onHide={() => setShowMobileConfirmModal(false)}
          onConfirm={handleConfirmMobileChange}
        />

        {/* Current Password Modal */}
        <CurrentPasswordModal
        show={showCurrentPasswordModal}
        onHide={() => setShowCurrentPasswordModal(false)}
        onConfirm={handlePasswordSubmit}
        errorMessage={passwordModalError}
        successMessage={passwordModalSuccess}
      />
      </div>
    </>
  );
}

export default AddProfileData;
